
import Company from "@/entities/domain/Company/Company";
import { PropType } from "vue";

export default {
	name: "DataPrivacy",
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
};
