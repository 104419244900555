// defineCustomElementWithStyles.js
import {
	defineCustomElement as VueDefineCustomElement,
	h,
	createApp,
	getCurrentInstance,
} from "vue";

// import VueMatomo from "vue-matomo";
import clickOutsideHandler from "./plugins/clickOutsideHandler";
import router from "./router";


const nearestElement = (el) => {
	while (el?.nodeType !== 1 /* ELEMENT */) el = el.parentElement;
	return el;
};

// Get all Styles from all components as custom elements with plugins
export const defineCustomElement = (component) =>
	VueDefineCustomElement({
		render: () => h(component),
		setup() {
			const app = createApp();

			app.use(router);

			app.use(clickOutsideHandler)

			app.mixin({
				mounted() {
					const insertStyles = (styles) => {
						if (styles?.length) {
							this.__style = document.createElement("style");
							this.__style.innerText = styles.join().replace(/\n/g, "");
							nearestElement(this.$el).prepend(this.__style);
						}
					};

					insertStyles(this.$?.type.styles);
					if (this.$options.components) {
						for (const comp of Object.values(this.$options.components)) {
							insertStyles(comp.styles);
						}
					}
				},
				unmounted() {
					this.__style?.remove();
				},
			});

			const inst = getCurrentInstance();
			Object.assign(inst.appContext, app._context);
			Object.assign(inst.provides, app._context.provides);

			return () => h(component);
		},
	});
