
import { defineComponent, PropType } from "vue";


import { Translations } from "@/entities/domain/Translations";
import Company from "@/entities/domain/Company/Company";
import { Product } from "@/entities/domain/Match/Product";
import { ResultButtonAction } from "@/entities/enums/QuizConfigEnums";
import ProductCardV2 from "@/components/BaseComponents/ProductCardV2.vue";
import GetDocumentElementById from "@/utils/GetDocumentElementById";


import QuizService from "@/services/QuizService";
import { Operator, ProductFilter, ProductListFilter } from "@/entities/domain/Match/ProductListFilter";
import { ProductFeedback } from "@/entities/domain/Match/ProductFeedback";

// import FloatingArrows from "@/components/BaseComponents/FloatingArrows.vue";
import FeedbackStars from "@/components/Elements/FeedbackStars.vue";

export default defineComponent({
	name: "ProfileChoice",
	components: {
		// FloatingArrows,
		// DialogComponent,
		FeedbackStars,
		// ProductGlassButton,
		// TasteType,
		// BottleCard,
		// EmailForm,
		// QrcodeVue,
		ProductCardV2,
		// Modal
	},
	props: {
		company: {
			type: Object as PropType<Company>,
			required: true,
		},
	},
	emits: ["answerClicked", "setAnswer", "closeQuiz"],
	data() {
		return {
			delay: 2000,
			loadingError: false,
			productSelected: null as Product | null,
			map: undefined,
			productImageSelected: null as Product | null,
			userReadDialog: false,

			isDialogOpened: false,

			productListFilterOptions: new ProductListFilter(),
			filteredProductList: this.company.Match?.Products as Product[],

			firstV2CardFlipped: true,

			showCouponModal: this.company.CompanyQuizConfig.CouponUrl ? true : false,

			// for Demo
			// emailQuestionProvided: true,
		};
	},
	computed: {
		innerWidth(): number {
			return window.innerWidth;
		},
		baseComponentTranslations(): Translations {
			return this.company?.baseComponentTranslations ?? {};
		},
		emailDialogText(): string {
			if (
				this.company.CompanyQuizConfig.ResultButtonAction ===
				ResultButtonAction.REDIRECTWITHWARNING
			)
				return this.baseComponentTranslations?.save?.text ?? "Speichern";

			return this.baseComponentTranslations?.send?.text ?? "Absenden";
		},
		showFeedbackQuestion(): boolean {
			if (
				this.userReadDialog ||
				!this.company.CompanyQuizConfig.OpenEmailDialogAfter
			)
				return true;

			return false;
		},
		headline(): string {

			if (this.company.CompanyQuizConfig.UseFormalSalutation)
				return this.company.DisplayedTexts["Diese Produkte passen perfekt zu Ihnen:"]

			return this.company.DisplayedTexts["Diese Produkte passen perfekt zu dir:"]
		},
	},
	mounted() {
		// When RestartButton is shown, autoreload should also be enabled
		if (
			this.company.CompanyQuizConfig.ShowRestartButton &&
			process.env.NODE_ENV !== "development"
		)
			setTimeout(() => this.company.restartQuiz(), 300000);

		if (this.company.CompanyQuizConfig.OpenEmailDialogAfter)
			setTimeout(() => {
				// if (!this.company.isEmailQuestionProvided) {
				this.isDialogOpened = true;
				// }
			}, this.company.CompanyQuizConfig.OpenEmailDialogAfter);

		setTimeout(() => {
			this.firstV2CardFlipped = false;
		}, this.delay);
	},
	methods: {
		closeDialog() {
			this.productSelected = null;
			this.productImageSelected = null;
			this.isDialogOpened = false;
		},
		scrollToTasteProfile() {
			GetDocumentElementById("tasteProfile")?.scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "nearest",
			});
		},

		markdownText(text: string) {
			const count = text.match(/(\*\*)/g);
			let result = text;
			if (count && count.length > 0) {
				for (let i = 0; i < count.length; i++) {
					result = result.replace("**", "<span style='font-weight: bold;'>");
					result = result.replace("**", "</span>");
				}
			}

			return result;
		},
		isProductAvailable(product: Product): boolean {
			if (this.company?.CompanyQuizConfig.ResultButtonAction ===
				ResultButtonAction.OPENNAVIGATIONMAP
			) {
				return true;
			} else if (
				this.company?.CompanyQuizConfig.ResultButtonAction ===
				ResultButtonAction.SAVEMODE
				||
				this.company?.CompanyQuizConfig.ResultButtonAction ===
				ResultButtonAction.STARTTASTING
			) {
				return true;
			} else return !!product.Handle;
		},
		onProductClicked(product: Product) {
			this.productImageSelected = null;
			this.productSelected = product;

			if (
				product.Handle &&
				this.company.CompanyQuizConfig.ResultButtonAction !==
				ResultButtonAction.SAVEMODE &&
				this.company.CompanyQuizConfig.ResultButtonAction !==
				ResultButtonAction.OPENNAVIGATIONMAP &&
				this.company?.CompanyQuizConfig.ResultButtonAction !==
				ResultButtonAction.STARTTASTING
			) {
				QuizService.sendEvent(JSON.stringify({ source: "quiz", product: product.Id }), "LinkOpened")
				window
					.open(
						(this.company.Match?.CompanyConfig?.ShopifyShopProductPrefix ?? "") +
						product.Handle,
						"_blank"
					)
					?.focus();
			} else if (
				this.company.CompanyQuizConfig.ResultButtonAction ===
				ResultButtonAction.REDIRECTWITHWARNING
			) {
				this.isDialogOpened = true;
			}
			else if (this.company.CompanyQuizConfig.ResultButtonAction === ResultButtonAction.STARTTASTING) {
				window.close()
			}
			else {
				QuizService.sendEvent(JSON.stringify({ source: "quiz", product: product.Id }), "NavigationOpened")
				this.isDialogOpened = true;
			}
		},
		emailFormReject() {
			if (
				this.company.CompanyQuizConfig.ResultButtonAction ===
				ResultButtonAction.REDIRECTWITHWARNING &&
				this.productSelected
			) {
				QuizService.sendEvent(JSON.stringify({ source: "quiz", product: this.productSelected.Id }), "LinkOpened")
				window
					.open(
						this.company.Match?.CompanyConfig?.ShopifyShopProductPrefix +
						this.productSelected.Handle,
						"_blank"
					)
					?.focus();

				this.isDialogOpened = false;
			}
		},
		onProductImageClicked(product: Product) {
			this.productSelected = null;
			this.productImageSelected = product;
		},
		productIsMealMatch(product) {
			return product.mealMatch > 0;
		},
		getPartnerMatch() {
			return parseInt(this.company?.Match?.Products[0] ? this.company?.Match?.Products[0].Handle : '')
		},
		noMealMatchGiven() {
			return (
				this.company?.Match?.Products.filter((product) => this.productIsMealMatch(product))
					.length === 0
			);
		},
		getTextPartBeforeUnderline(text: string | null | undefined): string {
			if (!text) return "Was macht deinen";

			return text.substring(0, text.indexOf("<underline>"));
		},
		getTextPartInbetweenUnderline(text: string | null | undefined): string {
			if (!text) return "Lieblingsprodukt";

			return text.substring(text.indexOf("<underline>") + 11, text.indexOf("</underline>"));
		},
		getTextPartAfterUnderline(text: string | null | undefined): string {
			if (!text) return "aus?";

			return text.substring(text.indexOf("</underline>") + 12);
		},
		emailFormSended() {
			if (
				this.company.CompanyQuizConfig.ResultButtonAction ===
				ResultButtonAction.REDIRECTWITHWARNING &&
				this.productSelected &&
				this.company.Match?.CompanyConfig
			)
				window
					.open(
						this.company.Match.CompanyConfig.ShopifyShopProductPrefix +
						this.productSelected.Handle,
						"_blank"
					)
					?.focus();
		},
		onProductFeedbackCauseSelected(feedbackData: ProductFeedback) {
			// Remove bad rated Product from matchingResult
			// Filter the Product ID to make sure the disliked product can't be displayed again
			// Add Filter for the Cause of the dislike, to remove similar products
			this.productListFilterOptions.ProductFilterOptions = [
				...this.productListFilterOptions.ProductFilterOptions,
				new ProductFilter({
					PropertyName: "Id",
					Operator: Operator.NotEqual,
					ValueToCompare: feedbackData.Product.Id
				} as ProductFilter)
			]

			this.productListFilterOptions.ProductFilterOptions = [
				...this.productListFilterOptions.ProductFilterOptions,
				new ProductFilter({
					PropertyName: feedbackData.Cause,
					Operator: Operator.NotEqual,
					ValueToCompare: feedbackData.Product[feedbackData.Cause]
				} as ProductFilter)
			]

			this.filteredProductList = this.productListFilterOptions.FilterProductList(this.company.Match?.Products ?? [], this.company.CompanyQuizConfig.AdvancedProductResultFiltering);
		},
		openCouponUrl() {
			window.open(this.company.CompanyQuizConfig.CouponUrl, '_blank');
		}

	},
});
