
import Company from "@/entities/domain/Company/Company";
import { QuestionType } from "@/entities/enums/QuizEnums";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "ProgressBar",
	props: {
		company: Object as PropType<Company>,
	},
	computed: {
		progress() {
			const noQuestionCount = this.company?.Quiz.Questions.filter(question => this.company?.Quiz.QuestionIdHistory.includes(question.Id)).length ?? 0;

			const questionCount = this.company?.Quiz?.QuestionIdHistory?.length ?? 0

			// if(questionCount > 2)
			// 	return questionCount - noQuestionCount;
			// else
				return questionCount ?? 0;
		},
		overall() {
			if(this.company?.CompanyQuizConfig?.QuizCountCorrection)
				return this.company?.Quiz.getQuestionCount() - this.company?.CompanyQuizConfig?.QuizCountCorrection  ?? 0;
			
			return this.company?.Quiz.getQuestionCount() ?? 0;
		},
	},
});
