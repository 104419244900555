import { Product } from "./Product";

export class ProductListFilter {
	private _ProductFilterOptions: ProductFilter[];

	constructor(ProductListFilter?: ProductListFilter) {
		this._ProductFilterOptions = ProductListFilter?._ProductFilterOptions ?? [];
	}

	get ProductFilterOptions(): ProductFilter[] {
		return this._ProductFilterOptions;
	}

	set ProductFilterOptions(options: ProductFilter[]) {
		let tempFilter = [...new Set([...options, ...this._ProductFilterOptions])];

		options.map((newFilterOption) => {
			if (newFilterOption.PropertyName === "Color") {
				tempFilter = tempFilter.filter((filter) => filter.PropertyName !== "Color");
				tempFilter.push(newFilterOption);
			}
		});

		this._ProductFilterOptions = tempFilter;
	}

	FilterProductList(productList: Product[], advancedFiltering = false): Product[] {
		let filteredProductList = productList;
		this.ProductFilterOptions.forEach((filter) => {
			filteredProductList = filter.FilterProductList(filteredProductList);
		});

		if (filteredProductList.length < 5 || advancedFiltering === false) {
			let largerProductList = productList;
			this.ProductFilterOptions.forEach((filter) => {
				if(filter.PropertyName === "Id")
					largerProductList = filter.FilterProductList(largerProductList);
			});

			filteredProductList = largerProductList;
		}

		return filteredProductList;
	}
}

export class ProductFilter {
	PropertyName: string;
	ValueToCompare: string;
	Operator: Operator;

	constructor(ProductFilter: ProductFilter) {
		this.PropertyName = ProductFilter.PropertyName;
		this.ValueToCompare = ProductFilter.ValueToCompare;
		this.Operator = ProductFilter.Operator;
	}

	FilterProductList(productList: Product[]): Product[] {
		return productList.filter((product) => {
			if (this.ValueToCompare === undefined) return true;

			switch (this.Operator) {
				case Operator.Equal:
					return product[this.PropertyName] === this.ValueToCompare;
				case Operator.NotEqual:
					return product[this.PropertyName] !== this.ValueToCompare;
				case Operator.GreaterThan:
					return product[this.PropertyName] > this.ValueToCompare;
				case Operator.GreaterThanOrEqual:
					return product[this.PropertyName] >= this.ValueToCompare;
				case Operator.LessThan:
					return product[this.PropertyName] < this.ValueToCompare;
				case Operator.LessThanOrEqual:
					return product[this.PropertyName] <= this.ValueToCompare;
				case Operator.Contains:
					return product[this.PropertyName].includes(this.ValueToCompare);
				case Operator.IsNull:
					return product[this.PropertyName] === null;
				case Operator.IsNotNull:
					return product[this.PropertyName] !== null;
				case Operator.Regex:
					return new RegExp(this.ValueToCompare).test(product[this.PropertyName]);
				default:
					return false;
			}
		});
	}
}

export enum Operator {
	Equal = "Equal",
	NotEqual = "NotEqual",
	GreaterThan = "GreaterThan",
	GreaterThanOrEqual = "GreaterThanOrEqual",
	LessThan = "LessThan",
	LessThanOrEqual = "LessThanOrEqual",
	Contains = "Contains",
	IsNull = "IsNull",
	IsNotNull = "IsNotNull",
	Regex = "Regex",
}
