
import { defineComponent, PropType } from "vue";
import Company from "@/entities/domain/Company/Company";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "StartChoice",
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
	emits: ["setAnswer"],
	computed: {
		baseComponentTranslations() {
			return this.company?.baseComponentTranslations;
		},
		answers(): Answer[] {
			return this.company?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
	methods: {
		answerClicked(answer: Answer) {
			this.$emit("setAnswer", { answerId: answer.Id, answerValue: answer.Value });
		},
	},
});
