import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-355191bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "SteplessSlider" }
const _hoisted_2 = { class: "picture" }
const _hoisted_3 = ["max"]
const _hoisted_4 = { class: "slider-answerRange" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageClickPosition = _resolveComponent("ImageClickPosition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.answers[_ctx.currentAnswer]?.Picture)
        ? (_openBlock(), _createBlock(_component_ImageClickPosition, {
            key: 0,
            src: _ctx.answers[_ctx.currentAnswer]?.Picture,
            alt: _ctx.answers[_ctx.currentAnswer].Text,
            onOnImageClick: _ctx.setValsFromImageClick
          }, null, 8, ["src", "alt", "onOnImageClick"]))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sliderValue) = $event)),
      type: "range",
      min: "1",
      max: _ctx.sliderRange,
      id: "tw-slider",
      step: "1",
      class: "slider",
      style: _normalizeStyle({
				background: `linear-gradient(to right, var(--stepless-slider-background-color) 0%, var(--stepless-slider-background-color) ${_ctx.gradiantSliderValue}%, #fff ${_ctx.gradiantSliderValue}%, ${
					_ctx.sliderValue < 99 ? '#fff' : 'var(--stepless-slider-background-color)'
				} 100% )`,
			}),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (
				_ctx.$emit('answerClicked', {
					answerId: _ctx.answers ? _ctx.answers[_ctx.currentAnswer].Id : null,
					answerValue: _ctx.sliderValue,
				})
			))
    }, null, 44, _hoisted_3), [
      [_vModelText, _ctx.sliderValue]
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.answers ? _ctx.answers[_ctx.currentAnswer].Text : "-"), 1)
  ]))
}