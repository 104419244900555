
import { Answer } from "@/entities/domain/Quiz/Answer";
import Company from "@/entities/domain/Company/Company";
import { Translations } from "@/entities/domain/Translations";
import QuizService from "@/services/QuizService";
import { defineComponent, PropType } from "vue";
import DataPrivacy from "../DataPrivacy.vue";
import DialogComponent from "../Dialog.vue";
import Customer from "../svg/Customer.vue";
import Mail from "../svg/Mail.vue";
import { Chatbot } from "@/entities/domain/Chatbot/Chatbot";

export default defineComponent({
	name: "EmailForm",
	props: {
		company: {
			type: Object as PropType<Company>,
			required: true,
		},
		infoText: String,
		confirmText: String,
		rejectText: String,
		chatbot: {
			type: Object as PropType<Chatbot>,
			required: false,
		},
	},
	emit: ["close", "formSend"],
	computed: {
		answers(): Answer[] {
			return this.company?.Quiz.getCurrentQuestion().Answers ?? [];
		},
		baseComponentTranslations(): Translations {
			return this.company.baseComponentTranslations;
		},
	},
	data() {
		return {
			name: "",
			email: "",
			dsgvo: false,
			errors: {
				name: null as string | null,
				email: null as string | null,
				dsgvo: null as string | null,
			},
			showDataPrivacy: false,
		};
	},
	watch: {
		async name() {
			await this.setValues();
		},
		async email() {
			await this.setValues();
		},
		async dsgvo() {
			await this.setValues();
		},
	},
	methods: {
		async setValues(sendDirectly = false) {
			this.email = this.email.toLowerCase();
			this.validateEmail();
			this.validateName();
			this.validateDsgvo();
			if (!this.answers) return;
			if (!this.company?.CompanyQuizConfig.QrCodeMode && !this.chatbot) {
				await this.company.onAnswerClicked(
					this.name && this.email && this.regexTestEmail() && this.dsgvo
						? {
							answerId: this.answers[0]?.Id,
							answerValue: JSON.stringify({
								firstname: this.name.slice(0, this.name.indexOf(" ")),
								lastname: this.name.slice(this.name.indexOf(" ") + 1),
								email: this.email,
							}),
						}
						: null
				);
			}

			if (this.chatbot) {
				await QuizService.sendEvent(
					JSON.stringify({
						firstname: this.name.slice(0, this.name.indexOf(" ")),
						lastname: this.name.slice(this.name.indexOf(" ") + 1),
						email: this.email,
					}),
					"email"
				);
			}

			if (sendDirectly && this.name && this.email && this.regexTestEmail() && this.dsgvo) {
				this.company.emailFormSend(true);
				this.$emit("formSend"); // TODO: When send and Messe mode show ProductClaiming :237 Emailchoice

				if (this.company?.CompanyQuizConfig.QrCodeMode) {
					await QuizService.sendEvent(
						JSON.stringify({
							firstname: this.name.slice(0, this.name.indexOf(" ")),
							lastname: this.name.slice(this.name.indexOf(" ") + 1),
							email: this.email,
						}),
						"email"
					);
				} else {

					if (!this.chatbot)
						await this.company.setAnswerAsync({
							answerId: this.answers[0]?.Id,
							answerValue: JSON.stringify({
								firstname: this.name.slice(0, this.name.indexOf(" ")),
								lastname: this.name.slice(this.name.indexOf(" ") + 1),
								email: this.email,
							}),
						});
					this.$emit("close");
				}
			}
		},
		validateName() {
			this.errors.name = null;
			if (!this.name) {
				this.errors.name =
					this.company.DisplayedTexts["Name ist erforderlich."];
			}
		},
		validateEmail() {
			this.errors.email = null;
			if (!this.email) {
				this.errors.email =
					this.company.DisplayedTexts["E-Mail-Adresse ist erforderlich."];
			}
			if (!this.regexTestEmail()) {
				this.errors.email =
					this.company.DisplayedTexts["Falsches E-Mail Format."];
			}
		},
		validateDsgvo() {
			this.errors.dsgvo = null;
			if (!this.dsgvo) {
				this.errors.dsgvo =
					this.company.DisplayedTexts["Sie müssen den Datenschutzbestimmungen zustimmen."];
			}
		},
		regexTestEmail() {
			return this.email.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
		},
		async claimProduct() {
			await QuizService.sendEvent(true, "productClaimed");
			window.location.reload();
		},
	},
	components: { Customer, Mail, DialogComponent, DataPrivacy },
});
