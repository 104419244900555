import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-438c9e81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "SingleChoice" }
const _hoisted_2 = { class: "answerForm" }
const _hoisted_3 = ["for", "onClick"]
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer) => {
        return (_openBlock(), _createElementBlock("label", {
          key: answer.Id,
          class: "answer",
          for: answer.Id.toString(),
          onClick: ($event: any) => (_ctx.$emit('setAnswer', answer.Score))
        }, [
          _createElementVNode("input", {
            id: answer.Id.toString(),
            type: "radio",
            name: "answer"
          }, null, 8, _hoisted_4),
          _createTextVNode(" " + _toDisplayString(answer.Text), 1)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}