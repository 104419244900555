import Company from "./Company/Company";
import displayedText from "@/assets/displayedText";

export default class DisplayedTexts {
	// For Dynamic extension
	[key: string]: string;


	constructor(company: Company) {

		// Fill DisplayedTexts with default texts
		displayedText.forEach((key) => {
			this[key] = key;
		});


		if(company.FrontendDesign?.ProductWizard?.TextConventions)
			Object.keys(company.FrontendDesign?.ProductWizard?.TextConventions).forEach((key) => {
				
				const answerVal = company.FrontendDesign?.ProductWizard?.TextConventions![key];
				
				this[key] = answerVal ?? key;
			})

		if(company.FrontendDesign?.TextConventions)
			Object.keys(company.FrontendDesign?.TextConventions).forEach((key) => {
				
				const answerVal = company.FrontendDesign?.TextConventions![key];
				
				this[key] = answerVal ?? key;
			})
	
			

		const translationTexts = company.baseComponentTranslations;
		const selectedLanguage = company.selectedLanguage;


		Object.keys(translationTexts).forEach((key) => {
			if (translationTexts[key] && translationTexts[key][selectedLanguage]) {
				this[key] = translationTexts[key][selectedLanguage];
			}
		})

		// Update object with translated texts if available
		Object.keys(this).forEach((key) => {
			if (translationTexts[key] && translationTexts[key][selectedLanguage]) {
				this[key] = translationTexts[key][selectedLanguage];
			}
		});

		// Apply text conventions if available
		if (company.FrontendDesign?.ProductWizard?.TextConventions) {
			const textConventions = company.FrontendDesign.ProductWizard.TextConventions;

			Object.keys(textConventions).forEach((key) => {
				const translationKey = textConventions[key];
				if (
					translationTexts[translationKey] &&
					translationTexts[translationKey][selectedLanguage]
				) {
					this[key] = translationTexts[translationKey][selectedLanguage];
				}
			});
		}

		if (company.FrontendDesign?.TextConventions) {
			const textConventions = company.FrontendDesign.TextConventions;

			Object.keys(textConventions).forEach((key) => {
				const translationKey = textConventions[key];
				if (
					translationTexts[translationKey] &&
					translationTexts[translationKey][selectedLanguage]
				) {
					this[key] = translationTexts[translationKey][selectedLanguage];
				}
			});
		}
		
	}
}
