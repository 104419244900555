import { createRouter, createWebHashHistory } from "vue-router";

import QuestionPage from "@/pages/QuestionPage.vue";
import ResultPage from "@/pages/QuizResultPage.vue";
import QuizSelectionPage from "@/pages/QuizSelectionPage.vue";
import LanguageSelectionPage from "@/pages/LanguageSelectionPage.vue";
import ChatBotModePage from "@/pages/ChatBotModePage.vue";
import CompanySelectionPage from "@/pages/CompanySelectionPage.vue";

const router = createRouter({
	history: createWebHashHistory(),
	routes: [
		{
			path: "/",
			name: "Chatbot",
			component: ChatBotModePage,
		},
		{
			path: "/languageSelection",
			name: "Language Selection",
			component: LanguageSelectionPage,
		},
		{
			path: "/question/:questionId",
			name: "Question",
			component: QuestionPage,
		},
		{
			path: "/result",
			name: "Quiz Result",
			component: ResultPage,
		},
		{
			path: "/quizSelection",
			name: "Quiz Selection",
			component: QuizSelectionPage,
		},
		{
			path: "/:pathMatch(.*)",
			name: "CompanySelection",
			component: CompanySelectionPage
		}
	],
});

export default router;
