import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f64d6e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "EmailChoice" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmailForm = _resolveComponent("EmailForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EmailForm, {
      company: _ctx.company,
      infoText: _ctx.infoText
    }, null, 8, ["company", "infoText"])
  ]))
}