
import { defineComponent, PropType } from "vue";
import Company from "@/entities/domain/Company/Company";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "MultipleChipSelection",
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
	emits: ["answerClicked"],
	data() {
		return {
			selectedChipAnswerIds: [] as number[],
		};
	},
	computed: {
		answers(): Answer[] {
			return this.company?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
	watch: {
		selectedChipAnswerIds: {
			deep: true,
			handler() {
				this.$emit("answerClicked", this.selectedChipAnswerIds);
			},
		},
	},
	methods: {
		onChipClicked(e: any) {
			const index = this.selectedChipAnswerIds.indexOf(Number(e.target.id));

			const selectionCopy = this.selectedChipAnswerIds;

			index !== -1 ? selectionCopy.splice(index, 1) : selectionCopy.push(Number(e.target.id));

			this.selectedChipAnswerIds = selectionCopy;
		},
	},
});
