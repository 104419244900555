
import Company from "@/entities/domain/Company/Company";
import { defineComponent } from "vue";
import { RouterView } from "vue-router";
import Loading from "./BaseComponents/Loading.vue";
import HeaderComponent from "@/components/Elements/Header.vue";
import FooterComponent from "./Elements/Footer.vue";
import QuizService from "@/services/QuizService";
import GetDocumentElementById from "@/utils/GetDocumentElementById";
import { Languages } from "@/entities/enums/QuizConfigEnums";

import Close from "@/components/svg/Close.vue";

export default defineComponent({
	name: "Quiz",
	components: { HeaderComponent, Loading, RouterView, FooterComponent, Close },
	data() {
		return {
			company: new Company(),
			loadingError: false,
		};
	},
	props: {
		autoRedirect: {
			type: Boolean,
			default: false,
		},
		companyCode: {
			type: String,
			required: true,
		},
		mode: {
			type: String,
			default: "normal",
		},
		transparentMode:
		{
			type: Boolean,
			default: false,
		},
		matchInfo:
		{
			type: String,
			default: ""
		},
		language: {
			type: String,
			default: "de",
		},
	},
	computed: {
		quizIsProperlyLoaded(): boolean {
			return (
				this.company.isCompanyLoaded &&
				this.company.Quiz.QuestionIdHistory.length > 0
			);
		},
		isFeatureModeBlocked(): boolean {
			const res = (this.company.CompanyQuizConfig.UrlToBlockFeatureModeOn !== undefined &&
			window.location.href.includes(this.company.CompanyQuizConfig.UrlToBlockFeatureModeOn))

			return res;
		}
	},
	async mounted() {
		localStorage.setItem("language", this.language);

		this.company.AutoRedirect = this.autoRedirect;

		// if (this.$route.name && this.$route.name != "Chatbot") await this.$router.push("/");

		let companyCodeToLoadQuizFor =
			window.localStorage.getItem("companyCode") ?? this.companyCode;

		// If CompanyCode is provided in url, use that
		const urlHashData = window.location.hash.slice(2); // Get everything after #/ in url

		if (
			urlHashData &&
			!urlHashData.includes("/") &&
			!this.$router.options.routes.find((route) => {
				if (route.path.includes("/:"))
					return urlHashData.includes(
						route.path.substring(1, route.path.indexOf("/:") - 1)
					);

				return urlHashData.includes(route.path.slice(1));
			})
			&& !["BESCHREIBUNG", "AUSZEICHNUNGEN", "LEBENSMITTELNACHWEIS"].some(keyword => urlHashData.toUpperCase().includes(keyword.toUpperCase()))
		) {
			companyCodeToLoadQuizFor = urlHashData.toUpperCase();
		}

		await this.company.loadSavedSessionAsync(this.matchInfo);
		const loadCompanyResponse = await this.company.loadCompanyAsync(
			companyCodeToLoadQuizFor
		);

		// Set Language 
		this.company.setLanguage(Languages[localStorage.getItem("language")!]);


		// if ((!loadCompanyResponse.Data || !loadCompanyResponse.Success) && companyCodeToLoadQuizFor !== this.companyCode) {
		// 	const newResult = await this.company.loadCompanyAsync(this.companyCode, true)
		// }

		// TODO: set this somewhere else?
		// Activate Matomo Screenshots
		// window["__paq"].push(["HeatmapSessionRecording::enable"]);

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					QuizService.sendEvent(true, "quizOpened");
				}
			});
		});

		const component = GetDocumentElementById("questionWrapper");
		if (component) observer.observe(component);

		setTimeout(() => {

			if (this.mode === "normal" && this.company.CompanyQuizConfig.ChatbotMode) {
				this.company.CompanyQuizConfig.ChatbotMode.QuizOnlyMode = false;
				this.company.isQuizClosed = false;
			}

			if (this.transparentMode) {
				this.company.homepageMode = true;
				this.company.CompanyQuizConfig.TransparentMode = true;
			}
		}, 200)
	},
	methods: {
		reloadQuiz() {
			window.location.reload();
		},
		handleQuizClose() {
			const isAutoOpenUrl = this.company.CompanyQuizConfig.AutoOpenUrls?.some(
				el => window.location.href.includes(el)
			);
			

			if (isAutoOpenUrl && this.company.FrontendDesign?.TextConventions &&
				this.company.FrontendDesign?.TextConventions["LogoLink"] 
				&& this.company.CompanyQuizConfig.AutoOpenUrls
				&& this.company.CompanyQuizConfig.AutoOpenUrls.length > 0
			) {
				window.location.href = this.company.FrontendDesign?.TextConventions["LogoLink"];
			} else {
				this.company.closeQuiz();
			}
		},
	},
});
