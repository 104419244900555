export default function DispatchEvent(name: string, data: any): void {
	if (process.env.NODE_ENV === "development") {
		document.dispatchEvent(
			new CustomEvent(name, {
				detail: data,
			})
		);
	} else {
		const shadowWilli = document
			.getElementById("ap-productfinder")
			?.shadowRoot?.getElementById("ap-productfinder");

		shadowWilli?.dispatchEvent(
			new CustomEvent(name, {
				detail: data,
			})
		);
	}
}
