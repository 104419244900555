
import SinglePictureChoice from "@/components/Question/SinglePictureChoice.vue";
import { Question } from "@/entities/domain/Quiz/Question";
import Company from "@/entities/domain/Company/Company";
import { Languages } from "@/entities/enums/QuizConfigEnums";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "LanguageSelectionPage",
	props: {
		company: {
			type: Object as PropType<Company>,
			required: true,
		},
	},
	components: { SinglePictureChoice },
	computed: {
		languageQuestion(): Question {
			return this.company.getLanguageQuestion();
		},
	},
	methods: {
		setAnswer(e) {
			const selectedLanguage = this.company.getLanguageQuestion().Answers[
				e.answerId
			].Text?.toUpperCase() as Languages;

			this.company.setLanguage(selectedLanguage);

			this.$router.push("/question/" + this.company.Quiz.getCurrentQuestion().Id);
		},
	},
	created() {
		if (this.company.languageWasSelected) {
			this.company.setLanguage(this.company.selectedLanguage);
			this.$router.push("/question/" + this.company.Quiz.getCurrentQuestion().Id);
		}
	},
});
