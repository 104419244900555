
import { defineComponent, PropType } from "vue";
import Company from "@/entities/domain/Company/Company";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "SingleChipSelection",
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
	emits: ["setAnswer"],
	data() {
		return {
			selectedChipAnswerId: -1,
		};
	},
	methods: {
		answerClicked(answer: Answer) {
			this.selectedChipAnswerId = answer.Id;
			this.$emit("setAnswer", { answerId: answer.Id, answerValue: Number(answer.Score) });
		},
	},
});
