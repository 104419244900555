
import FeedbackOverlay from "@/components/BaseComponents/FeedbackOverlay.vue";
import { Feedback } from "@/entities/domain/General/Feedback";
import { Product } from "@/entities/domain/Match/Product";
import Company from "@/entities/domain/Company/Company";
import { Translations } from "@/entities/domain/Translations";
import {
  StylingVariant,
  ResultButtonAction,
} from "@/entities/enums/QuizConfigEnums";
import QuizSelectionPage from "@/pages/QuizSelectionPage.vue";
import QuizService from "@/services/QuizService";
import { tsUndefinedKeyword } from "@babel/types";
import { ProductFeedbackCause } from "@/entities/enums/ProductFeedbackCause";

import { defineComponent, nextTick, PropType } from "vue";

export default defineComponent({

  name: "ProductCard",
  components: {
    FeedbackOverlay
  },
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
    stylingVariant: {
      type: String as PropType<StylingVariant>,
      default: StylingVariant.NORMAL,
    },
  },
  emits: ["productImageClicked", "productClicked", "productFeedbackCauseSelected"],
  data() {
    return { isFeedbackOverlayVisible: false, favorite: false, dislike: false, btgButtonText: this.company.DisplayedTexts["Verkostung starten"] };
  },

  async mounted() {
    await this.setBtgButtonText();
  },

  computed: {
    baseComponentTranslations(): Translations {
      return this.company.baseComponentTranslations;
    },
    productButtonText(): string {
      if (
        !this.company.isIGetNowModeActive &&
        this.product.Handle &&
        this.company.CompanyQuizConfig.ResultButtonAction !==
        ResultButtonAction.SAVEMODE &&
        this.company.CompanyQuizConfig.ResultButtonAction !==
        ResultButtonAction.OPENNAVIGATIONMAP &&
        this.company.CompanyQuizConfig.ResultButtonAction !==
        ResultButtonAction.STARTTASTING &&
        this.company.CompanyQuizConfig.ResultButtonAction !==
        ResultButtonAction.EXPLOREMODE &&
        this.company.CompanyQuizConfig.ResultButtonAction !==
        ResultButtonAction.SHOWQRCODE
      )
        return this.isProductAvailable
          ? this.company.DisplayedTexts["Jetzt kaufen"]
          : this.company.DisplayedTexts["Nicht verfügbar"];
      else if (this.company.isIGetNowModeActive)
        return this.isProductAvailable
          ? this.company.DisplayedTexts["zur Karte"]
          : this.company.DisplayedTexts["Nicht verfügbar"];
      else if (
        this.company.CompanyQuizConfig.ResultButtonAction ===
        ResultButtonAction.OPENNAVIGATIONMAP
      )
        return this.isProductAvailable
          ? this.company.DisplayedTexts["Navigation öffnen"]
          : this.company.DisplayedTexts["Nicht verfügbar"];
      else if (
        this.company.CompanyQuizConfig.BTGMode
      )
        return (
          this.company.DisplayedTexts["Verkostung starten"]
        );
      else if (
        this.company.CompanyQuizConfig.ResultButtonAction ===
        ResultButtonAction.SHOWQRCODE
      )
        return this.company.DisplayedTexts["Speichern"];
      else if(
        this.company.CompanyQuizConfig.ResultButtonAction ===
        ResultButtonAction.EXPLOREMODE
      )
        return this.company.DisplayedTexts["Jetzt Entdecken"];

      return this.company.DisplayedTexts["Speichern"];
    },
    isProductAvailable(): boolean {
      return this.company.isProductAvailable(this.product);
    },
    dislikeFeedbackReasons(): Feedback[] {
      return [
        {
          Title: this.company.DisplayedTexts["Weinfarbe"],
          Value: ProductFeedbackCause.COLOR
        },
        {
          Title: this.company.DisplayedTexts["Preis"],
          Value: ProductFeedbackCause.PRICE
        },
        {
          Title: this.company.DisplayedTexts["Geschmack"],
          Value: "taste",
          SubSelection: [
            {
              Title: this.company.DisplayedTexts["Marke"],
              Value: ProductFeedbackCause.TASTE_BRAND
            },
            {
              Title: this.company.DisplayedTexts["Rebsorte"],
              Value: ProductFeedbackCause.TASTE_GRAPE
            },
            {
              Title: this.company.DisplayedTexts["Süßegrad"],
              Value: ProductFeedbackCause.TASTE_SWEETNESS
            },
            {
              Title: this.company.DisplayedTexts["Säuregehalt"],
              Value: ProductFeedbackCause.TASTE_ACIDITY
            }
          ]
        },
        {
          Title: this.company.DisplayedTexts["Etikett"],
          Value: "label",
          SubSelection: [
            {
              Title: this.company.DisplayedTexts["Zu modern"],
              Value: ProductFeedbackCause.LABEL_TOO_MODERN
            },
            {
              Title: this.company.DisplayedTexts["Zu altbacken"],
              Value: ProductFeedbackCause.LABEL_TOO_OLD_FASHIONED
            },
            {
              Title: this.company.DisplayedTexts["Zu billig"],
              Value: ProductFeedbackCause.LABLE_TOO_CHEAP
            },
            {
              Title: this.company.DisplayedTexts["Zu kompliziert"],
              Value: ProductFeedbackCause.LABLE_TOO_COMPLICATED
            }
          ]
        }
      ]
    }
  },
  methods: {
    sendFeedback(cause?) {

      if (cause === undefined)
        this.favorite = !this.favorite;

      if (cause === undefined && !this.favorite) return;

      if (cause !== undefined)
        this.dislike = !this.dislike;

      QuizService.sendEvent(JSON.stringify({ product: this.product.Id, wasGood: cause === undefined, cause: cause }), "ProductFeedback");
      setTimeout(() => {
        this.isFeedbackOverlayVisible = false;
        if (cause !== undefined)
          this.$emit("productFeedbackCauseSelected", { Product: this.product, Cause: cause } as { Product: Product, Cause: ProductFeedbackCause })
      }, 2000)
    },
    async setBtgButtonText() {
      const gtins = await this.company.getBtgGtins();
      if (this.company.CompanyQuizConfig.ResultButtonAction === ResultButtonAction.STARTTASTING || (this.company.CompanyQuizConfig.BTGMode && gtins && gtins.includes(this.product.GTIN)))
        this.btgButtonText = this.company.DisplayedTexts["Verkostung starten"];
      else
        this.btgButtonText = this.company.DisplayedTexts["Navigation öffnen"];
    }
  }
});
