
import Company from "@/entities/domain/Company/Company";
import getImageFromSource from "@/utils/getImageFromSouce";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "HeaderComponent",
	props: {
		company: {
			type: Object as PropType<Company>,
			default: {} as Company,
		},
	},
	methods: {
		logoClicked() {
			if (this.company.FrontendDesign?.TextConventions?.LogoLink) {
				window.location.href = this.company.FrontendDesign.TextConventions.LogoLink;
			}
		},
	},
	computed: {
		brandLogoImageSource() {
			return getImageFromSource(
				this.company?.FrontendDesign?.BrandLogoImage ??
				"@/assets/img/bot-logo.png"
			);
		},
	},
});
