
import { Question } from "@/entities/domain/Quiz/Question";
import Company from "@/entities/domain/Company/Company";
import { Translations } from "@/entities/domain/Translations";
import getImageFromSource from "@/utils/getImageFromSouce";
import { defineComponent, PropType } from "vue";
import AGB from "../AGB.vue";
import DataPrivacy from "../DataPrivacy.vue";
import DialogComponent from "../Dialog.vue";

export default defineComponent({
	name: "FooterComponent",
	components: {
		AGB,
		DataPrivacy,
		DialogComponent,
	},
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
	data() {
		return {
			openDialogFor: null as null | string,
		};
	},
	computed: {
		currentQuestion(): Question {
			if(!this.company?.Quiz.getCurrentQuestion) return {} as Question;

			return this.company?.Quiz.getCurrentQuestion() ?? ({} as Question);
		},
		baseComponentTranslations(): Translations {
			return this.company?.baseComponentTranslations ?? {};
		},
	},
	methods: {
		getImageFromSource,
	},
});
