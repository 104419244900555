
import Company from "@/entities/domain/Company/Company";
import { StylingVariant } from "@/entities/enums/QuizConfigEnums";
import { defineComponent, PropType } from "vue";
import { MessageSender } from "@/entities/enums/ChatbotEnums";
import { FirstLevelSupport, SupportAnswer, SupportFlowItemAnswerType, SupportMessage } from "@/entities/domain/FirstLevelSupport/FirstLevelSupport";

export default defineComponent({
    name: "SingleMessage",
    props: {
        message: {
            type: Object as PropType<SupportMessage>,
            required: true,
        },
        company: {
            type: Object as PropType<Company>,
            required: true,
        },
        avatar: {
            type: String,
            required: true,
        },
        firstLevelSupport: {
            type: Object as PropType<FirstLevelSupport>,
            required: true,
        }
    },
    computed: {
        isMessageSenderWilli() {
            return this.message.Sender === MessageSender.WILLI;
        },
        stylingVariant() {
            return StylingVariant.CHATBOT;
        },
        chatbotTexts() {
            return this.company.FrontendDesign?.ProductWizard?.TextConventions ?? undefined;
        },
        messageLink() {
            // when message text contains <a> return the text inbetween otherwise null
            if (this.message.Text.includes("<a>")) {
                // Get the text between <a> and </a>
                return this.message.Text.substring(this.message.Text.indexOf("<a>") + 3, this.message.Text.indexOf("</a>"))

            }

            return null;
        },
        messageText() {
            // Get the message Text without links
            if (this.message.Text.includes("<a>")) {
                return this.message.Text.substring(0, this.message.Text.indexOf("<a>"))
            }

            return this.message.Text
        }
    },
    methods: {
        answerClicked(answer: SupportAnswer) {
            if (answer.Type === SupportFlowItemAnswerType.STANDARD)
                this.firstLevelSupport.SetAnswerForQuiz(answer)
            if (answer.Type === SupportFlowItemAnswerType.WINE_CONSULTANT) {
                this.$emit('openProductConsultant')
            }
            if (answer.Type === SupportFlowItemAnswerType.LINK && answer.Text) {
                const [beforeBrackets, innerText] = answer.Text.split(/\[(.*?)\]/).filter(Boolean);
                window.open(innerText, '_blank');

                this.firstLevelSupport.SetAnswerForQuiz({
                    ...answer,
                    Text: beforeBrackets
                } as SupportAnswer)
            }
            if (answer.Type === SupportFlowItemAnswerType.PACKAGE_TRACKING) {
                this.$emit("openPackageTracking")
                this.firstLevelSupport.StartTracking(answer)
            }
            if (answer.Type === SupportFlowItemAnswerType.CLOSE)
                this.$emit('close')
        },
        mapAnswerText(answer: SupportAnswer) {
            if (answer.Type === SupportFlowItemAnswerType.LINK && answer.Text) {
                const [beforeBrackets] = answer.Text.split(/\[(.*?)\]/).filter(Boolean);
                return beforeBrackets
            }
            return answer.Text
        }
    }
});
