import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7efea266"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.prevButtonIsVisible)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "button",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.company?.Quiz.returnToPrevQuestion()), ["prevent"])),
          style: _normalizeStyle(_ctx.company?.FrontendDesign?.BackgroundColor?.toLowerCase() === '#1e1e1e' && _ctx.company.FrontendDesign.PrimaryColor?.toLowerCase() !== '#ffffff' ? 'color: #ffffff' : _ctx.company?.FrontendDesign?.BackgroundColor)
        }, _toDisplayString(_ctx.company?.DisplayedTexts["Zurück"].toUpperCase()), 5))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      class: _normalizeClass(["button next", { disabled: _ctx.currentAnswerValue }]),
      disabled: !_ctx.answerIsSelected,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.company?.setAnswerAsync(null))),
      style: _normalizeStyle(_ctx.company?.FrontendDesign?.BackgroundColor?.toLowerCase() === '#1e1e1e' && _ctx.company.FrontendDesign.PrimaryColor?.toLowerCase() !== '#ffffff' ? 'color: #ffffff' : _ctx.company?.FrontendDesign?.BackgroundColor)
    }, _toDisplayString(_ctx.company?.DisplayedTexts["Weiter"]), 15, _hoisted_2)
  ]))
}