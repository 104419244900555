
import { defineComponent, PropType } from "vue";

import addToStyling from "@/utils/AddToStyling";
import Company from "@/entities/domain/Company/Company";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "BinaryChoice",
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
	emits: ["answerClicked", "setAnswer"],
	data() {
		return {
			clickedAnswer: null,
		};
	},
	computed: {
		answers(): Answer[] {
			return this.company?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
	mounted() {
		addToStyling(`
		#ap-productfinder .slider:before {
			background: url("${process.env.VUE_APP_FILE_SPACE}img/ja_aktiv.svg");
		}
		#ap-productfinder input:checked + .slider:before {
			background: url("${process.env.VUE_APP_FILE_SPACE}img/nein_aktiv.svg");
		}
		#ap-productfinder .BinaryChoice .slider:after {
			content: "${this.company?.DisplayedTexts["Ja"].toUpperCase()}";
			color: #333333;
			font-family: SecondaryFontFamily, "Open Sans", sans-serif;
			display: block;
			position: absolute;
			transform: translate(-50%, -50%);
			top: 50%;
			right: ${this.company?.selectedLanguage === 'de' ? "20%" : "10%"};
			font-size: 20px;
			font-weight: bold;
		}

		#ap-productfinder .BinaryChoice input:checked + .slider:after {
			content: "${this.company?.DisplayedTexts["Nein"].toUpperCase()}";
			left: 35%;
			top: 50%;
			color: white;
		}
		`);

		this.$emit("answerClicked", null);
	},
	methods: {
		clickAnswer(answerIndex, answerValue) {
			this.clickedAnswer = answerIndex;
			this.$emit("setAnswer", {
				answerId: this.answers[answerIndex].Id,
				answerValue: answerValue,
			});
		},
	},
});
