import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3711fcb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tt_wrapper",
  id: "questionWrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Question = _resolveComponent("Question")!
  const _component_Navigation = _resolveComponent("Navigation")!

  return (_ctx.company?.Quiz)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (
				_ctx.company?.Quiz.getCurrentQuestion().Type !== 'StartChoice' &&
				_ctx.company?.Quiz.getCurrentQuestion().Type !== 'ProfileChoice'
			)
          ? (_openBlock(), _createBlock(_component_ProgressBar, {
              key: 0,
              company: _ctx.company
            }, null, 8, ["company"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Question, { company: _ctx.company }, null, 8, ["company"]),
        (
				!_ctx.company.Quiz.getCurrentQuestion() ||
				(_ctx.company?.Quiz.getCurrentQuestion().Type !== 'StartChoice' &&
					_ctx.company?.Quiz.getCurrentQuestion().Type !== 'ProfileChoice')
			)
          ? (_openBlock(), _createBlock(_component_Navigation, {
              key: 1,
              company: _ctx.company
            }, null, 8, ["company"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}