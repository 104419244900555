import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c9447a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["singleMessage", { sendedFromWilli: _ctx.isMessageSenderWilli }])
  }, [
    (_ctx.isMessageSenderWilli &&
            (_ctx.message.Type === 'NORMAL' || _ctx.message.Type === 'IGNOREINHISTORY')
            )
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["avatarContainer", { center: true, border: _ctx.chatbotTexts?.showIconBorder === 'true' }]),
          style: _normalizeStyle({ backgroundColor: _ctx.company.FrontendDesign?.ProductWizard?.IconBackgroundColor ?? _ctx.company.FrontendDesign?.ProductWizard?.PrimaryColor })
        }, [
          _createElementVNode("img", { src: _ctx.avatar }, null, 8, _hoisted_1)
        ], 6))
      : _createCommentVNode("", true),
    (_ctx.message.Type === 'NORMAL' || _ctx.message.Type === 'IGNOREINHISTORY')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["message", {
            sendedFromWilli: _ctx.isMessageSenderWilli,
            normal: _ctx.message.Type === 'NORMAL' || _ctx.message.Type === 'IGNOREINHISTORY',
        }])
        }, [
          _createTextVNode(_toDisplayString(_ctx.messageText) + " ", 1),
          (_ctx.messageLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                target: "_blank",
                href: _ctx.messageLink
              }, _toDisplayString(_ctx.messageLink), 9, _hoisted_2))
            : _createCommentVNode("", true)
        ], 2))
      : (_ctx.message.Type === 'ANSWERSELECTION')
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["messageActions answerSelection", { 'row': (_ctx.message.AnswerSelection?.length && _ctx.message.AnswerSelection?.length > 5) }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.message.AnswerSelection, (answer) => {
              return (_openBlock(), _createElementBlock("button", {
                class: _normalizeClass(["secondary", _ctx.message.SecondaryButtonStyle]),
                key: answer.Id,
                onClick: ($event: any) => (_ctx.answerClicked(answer)),
                style: _normalizeStyle({ marginTop: answer.Type === 'CLOSE' ? '1rem' : '0', backgroundColor: answer.Type === 'CLOSE' ? _ctx.company.FrontendDesign?.ProductWizard?.PrimaryColor : '', color: answer.Type === 'CLOSE' ? _ctx.company.FrontendDesign?.ProductWizard?.FontColorPrimary : '' })
              }, _toDisplayString(_ctx.mapAnswerText(answer)), 15, _hoisted_3))
            }), 128))
          ], 2))
        : _createCommentVNode("", true)
  ], 2))
}