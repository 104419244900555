
import { Answer } from "@/entities/domain/Quiz/Answer";
import { defineComponent, PropType } from "vue";
import Blob from "@/components/svg/Blob.vue";

export default defineComponent({
	name: "MultiplePictureChoiceButton",
	components: {
		Blob,
	},
	props: {
		answer: {
			type: Object as PropType<Answer>,
			default: null,
		},
		selectedAnswerTags: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		showCaption: {
			type: Boolean,
		},
		small: {
			type: Boolean,
		},
		selectedAnswer: {
			type: Boolean,
		},
		versusMode: {
			type: Boolean,
			default: false,
		},
		textColor: {
			type: String
		}
	},
	methods: {
		getImg(img: string | null | undefined) {
			if (!img) return null;

			if (img[0] === "/") {
				return require("@/assets" + img);
			} else if (img[0] === "@") {
				return require("@/assets" + img.replace("@/assets", ""));
			} else {
				return img;
			}
		},
	},
	computed:{
		answerImage(){
			if(!this.answer.TaggedPictures || this.answer.TaggedPictures.length === 0 || !this.selectedAnswerTags || this.selectedAnswerTags.length === 0){
				return this.answer.Picture;
			}
			else{
				// Search if tag is fitting from a previos answer
				return this.answer.TaggedPictures.find(el => this.selectedAnswerTags.includes(el.Tag))?.Picture ?? this.answer.Picture;
			}

		}
	}
});
