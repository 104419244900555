
import { Feedback } from '@/entities/domain/General/Feedback';
import QuizService from '@/services/QuizService';
import { defineComponent, PropType } from 'vue';
import Checkmark from '@/components/svg/Checkmark.vue';
import Company from '@/entities/domain/Company/Company';

export default defineComponent({
    name: 'FeedbackOverlay',
    components: { Checkmark },
    props: {
        reasonArray: {
            type: Object as PropType<Feedback[]>,
            required: true
        },
        heightBlur: {
            type: String,
            required: false
        },
        widthBlur: {
            type: String,
            required: false
        },
        borderRadius: {
            type: String,
            required: false
        },
        company: {
            type: Object as PropType<Company>,
            required: true
        }
    },
    data() {
        return {
            activeReasonArray: [] as Feedback[],
            feedbackSended: false
        }
    },
    mounted() {
        this.activeReasonArray = this.reasonArray;
    },
    emits: ["feedbackReasonSelected", "closeClick"],
    methods: {
        reasonSelected(value: string) {
            const selectedReason = this.activeReasonArray.find(el => el.Value === value);

            if (!selectedReason) return;

            if (!selectedReason.SubSelection || selectedReason.SubSelection?.length === 0)
                this.sendFeedback(value);
            else
                this.activeReasonArray = selectedReason.SubSelection;
        },
        sendFeedback(value: string) {
            // await QuizService.sendEvent(value, "QuizFeedback");
            this.feedbackSended = true;
            this.$emit('feedbackReasonSelected', value)
        }
    }
})
