
import { defineComponent, PropType } from "vue";

import Company from "@/entities/domain/Company/Company";
import { Answer } from "@/entities/domain/Quiz/Answer";
import EmailForm from "../Forms/EmailForm.vue";

export default defineComponent({
	name: "EmailChoice",
	components: {
		EmailForm,
	},
	props: {
		company: {
			type: Object as PropType<Company>,
			required: true,
		},
	},
	emits: ["close", "closeQuiz"],
	data() {
		return {
			name: "",
			email: "",
			dsgvo: false,
			errors: {
				name: null as string | null,
				email: null as string | null,
				dsgvo: null as string | null,
			},
		};
	},
	computed: {
		answers(): Answer[] {
			return this.company?.Quiz.getCurrentQuestion().Answers ?? [];
		},
		baseComponentTranslations() {
			return this.company?.baseComponentTranslations;
		},
		infoText() {
			const headlineText = this.company?.Quiz.getCurrentQuestion().Headline;


			if(headlineText && !this.company?.CompanyQuizConfig.QrCodeMode)
				return headlineText;

			if(this.company.CompanyQuizConfig.showPartnerMatching)
				return ""

			
			return this.company.DisplayedTexts["Welches Produkt passt wohl zu dir?<br />Hinterlasse uns deine E-Mail-Adresse und du erfährst direkt welcher Produkt-Typ du bist:"];
		
		},
	},
});
