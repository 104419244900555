import QuizService from "@/services/QuizService";
import GetDocumentElementById from "@/utils/GetDocumentElementById";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { nextTick } from "vue";
import ensureClosedSentence from "@/utils/ensureClosedSentence";
import { MessageTypes, MessageSender } from "@/entities/enums/ChatbotEnums";
import { QuestionType } from "@/entities/enums/QuizEnums";
import { Match } from "../Match/Match";
import { Product } from "../Match/Product";
import { OperationResult } from "../OperationResult";
import { Answer } from "../Quiz/Answer";
import Quiz from "../Quiz/Quiz";
import { Message } from "./Message";
import { ProductListFilter } from "../Match/ProductListFilter";
import Company from "../Company/Company";
import wordblockList from "./wordblockList.json";
import { Languages } from "@/entities/enums/QuizConfigEnums";

export class Chatbot {
	private _loading = false;

	MessageHistory: Message[];
	CurrentQuestion?: string;
	ExtraQuiz?: Quiz;
	ExpertQuiz?: Quiz;
	Token: string;

	ProductFilter?: ProductListFilter;
	isExpertQuizSet?: boolean;

	constructor(chatbot?: Chatbot) {
		this.MessageHistory = chatbot?.MessageHistory ?? ([] as Message[]);
		this.ExtraQuiz = chatbot?.ExtraQuiz;
		this.ExpertQuiz = chatbot?.ExpertQuiz;
		this.Token = chatbot?.Token ?? "";

		this.ProductFilter = chatbot?.ProductFilter ?? new ProductListFilter();
	}

	async SendMessage(
		company: Company,
		useMatching?: boolean,
		endPhrase?: string,
		logRequest?: boolean,
		quizId?: number
	) {
		this._loading = true;

		const simplifiedQuestion = this.CurrentQuestion?.toLowerCase().replaceAll(" ", "");

		this.isExpertQuizSet = false;

		if (this.CurrentQuestion)
			this.MessageHistory.push({
				Id: this.MessageHistory.length + 1,
				Text: this.CurrentQuestion,
				Type: MessageTypes.NORMAL,
				Sender: MessageSender.USER,
			});

		// Prevent Injection
		if (
			wordblockList.some((word) =>
				this.CurrentQuestion?.toLowerCase().includes(word.toLowerCase())
			) ||
			(this.CurrentQuestion?.length && this.CurrentQuestion?.length > 100)
		) {
			this.MessageHistory.push({
				Id: this.MessageHistory.length + 1,
				Text: "Bitte versuche nicht, unser Product Language Model zu manipulieren oder zu missbrauchen. ",
				Type: MessageTypes.IGNOREINHISTORY,
				Sender: MessageSender.WILLI,
			});

			this._loading = false;

			this.MessageHistory.push({
				Id: this.MessageHistory.length + 1,
				Text: "Neu laden",
				Type: MessageTypes.ONEBUTTON,
				Sender: MessageSender.WILLI,
				PrimaryFunction: () => {
					location.reload();
				},
			});

			return;
		}

		if (
			this.CurrentQuestion &&
			simplifiedQuestion &&
			(simplifiedQuestion.includes("passtzumir") ||
				simplifiedQuestion.includes("passtdazu") ||
				simplifiedQuestion.includes("empfiehlstduda") ||
				simplifiedQuestion.includes("anbieten") ||
				simplifiedQuestion.includes("shop"))
		) {
			const localMessageHistoryCopy = this.MessageHistory.slice().reverse();

			const idOfLastAnswer = localMessageHistoryCopy.find(
				(message) =>
					message.Sender === MessageSender.WILLI && message.Type === MessageTypes.NORMAL
			)?.Id;

			const answer = this.MessageHistory.find((message) => message.Id === idOfLastAnswer);

			const recommendations = await this.GetProductRecommendationForAnswer(answer?.Text ?? "", company.selectedLanguage);

			if (!recommendations || recommendations.length === 0)
				this.MessageHistory.push({
					Id: this.MessageHistory.length + 1,
					Text: company.DisplayedTexts["Leider kann ich dazu keine Produkte finden."],
					Type: MessageTypes.IGNOREINHISTORY,
					Sender: MessageSender.WILLI,
				});
			else {
				let productsToUse = recommendations.filter(
					(product) => product.PictureFront !== null && product.Price && product.Price > 6
				);

				if (!productsToUse)
					productsToUse = recommendations.filter((product) => product.Price && product.Price > 6);

				this.MessageHistory.push({
					Id: this.MessageHistory.length + 1,
					Text: productsToUse[0].Name ?? recommendations[0].Name,
					Type: MessageTypes.WINECARD,
					Sender: MessageSender.WILLI,
					ProductRecommendations: productsToUse ?? recommendations,
				});
			}

			return;
		} else {
			const questionToSend = "";

			// Check if match is given in localstorage to use the data
			const match = JSON.parse(localStorage.getItem("match") ?? "{}") as Match;


			const chatToSend = [{ role: "user", content: questionToSend }].concat(
				this.MessageHistory.filter((message) => message.Type === MessageTypes.NORMAL).map(
					(message) => {
						return {
							role: message.Sender === MessageSender.WILLI ? "assistant" : "user",
							content: message.Text,
						};
					}
				)
			);

			if (endPhrase) chatToSend.push({ role: "user", content: endPhrase });

			const data = JSON.stringify({
				question: chatToSend,
				logRequest,
				quizId,
			});

			const config = {
				method: "post",
				url: process.env.VUE_APP_API_SERVICE_PATH + "/tool/chat",
				headers: {
					Authorization: "Bearer " + QuizService.token,
					"Content-Type": "application/json",
				},
				data: data,
			} as AxiosRequestConfig;

			try {
				const response = await axios({ ...config, timeout: 25000 });

				let answerFromAi = response.data[0].message.content;

				if (answerFromAi.indexOf("?") < 3) answerFromAi = answerFromAi.replace("?", "");
				if (answerFromAi.indexOf(",") < 3) answerFromAi = answerFromAi.replace(",", "");

				// Make History ignored by AI but keep locally
				// this.MessageHistory = this.MessageHistory.map(message => {
				// 	// make each message as Ignore type
				// 	return {
				// 		...message,
				// 		Type: MessageTypes.IGNOREINHISTORY
				// 	} as Message
				// })

				answerFromAi = answerFromAi.trim();

				answerFromAi = ensureClosedSentence(answerFromAi);

				this.MessageHistory.push({
					Id: this.MessageHistory.length + 1,
					Text: answerFromAi,
					Type: MessageTypes.NORMAL,
					Sender: MessageSender.WILLI,
				});

				this._loading = false;

				await this.SendProductRecommendationInChat(company.selectedLanguage);
				return new OperationResult<any>({
					Success: true,
					Data: {
						sendedText: chatToSend,
						response: answerFromAi,
					},
				});
			} catch (error: any) {
				console.error({ error });

				// Make userSended Message ignored to resend
				this.MessageHistory[this.MessageHistory.length - 1].Type =
					MessageTypes.IGNOREINHISTORY;

				if (error.code === "ECONNABORTED") {
					this.MessageHistory.push({
						Id: this.MessageHistory.length + 1,
						Text: company.DisplayedTexts[
							"Die Anfrage hat zu lange gedauert, versuche es bitte später noch einmal."
						],
						Type: MessageTypes.IGNOREINHISTORY,
						Sender: MessageSender.WILLI,
					});
				} else {
					this.MessageHistory.push({
						Id: this.MessageHistory.length + 1,
						Text: company.DisplayedTexts[
							"Leider kann ich dir gerade keine Antwort geben, versuche es bitte später noch einmal."
						],
						Type: MessageTypes.IGNOREINHISTORY,
						Sender: MessageSender.WILLI,
					});
				}
				this.MessageHistory.push({
					Id: this.MessageHistory.length + 1,
					Text: "Erneut senden",
					Type: MessageTypes.ONEBUTTON,
					Sender: MessageSender.WILLI,
					PrimaryFunction: () => {
						this.MessageHistory.splice(-3);
						this.SendMessage(company, useMatching);
					},
				});
				this._loading = false;

				return new OperationResult({
					Success: false,
				});
			}
		}
	}

	// TODO: Find better Solution for Token
	async GetProductRecommendationForAnswer(answer: string, language: Languages): Promise<Product[]> {
		if (!answer || !this.Token) return [];

		this._loading = true;

		const data = JSON.stringify({
			answer,
			language: language
		});

		const config = {
			method: "post",
			url: process.env.VUE_APP_API_SERVICE_PATH + "/tool/chat/recommendation",
			headers: {
				Authorization: "Bearer " + QuizService.token,
				"Content-Type": "application/json",
			},
			data: data,
		} as AxiosRequestConfig;

		try {
			const response = await axios(config);

			this._loading = false;

			const responseProducts = response.data
				.filter((el) => el)
				.map((el) => Product.createFromApiResponse(el));

			return responseProducts;
		} catch (error) {
			this._loading = false;
			console.error({ error });
			return [];
		}
	}

	RestartConversation(firstMessage?: string) {
		if (firstMessage) {
			this.MessageHistory.push({
				Id: 1,
				Text: firstMessage,
				Type: MessageTypes.IGNOREINHISTORY,
				Sender: MessageSender.WILLI,
			});
		}
	}

	StartConversationWithQuiz(isExpertQuiz?: boolean) {
		// this.MessageHistory = [];
		this.CurrentQuestion = undefined;
		if (isExpertQuiz) {
			this.isExpertQuizSet = true;
		}

		this.PushNextQuizQuestion();
	}

	PushNextQuizQuestion(lastAnswer?: Answer) {
		if (this.isExpertQuizSet) {
			const nextQuestion = lastAnswer?.NextQuestion
				? this.ExpertQuiz?.Questions.find((q) => q.Id === lastAnswer.NextQuestion)
				: this.ExpertQuiz?.Questions[0];

			if (nextQuestion) this.ExpertQuiz?.QuestionIdHistory.push(nextQuestion.Id);

			const nextQuestionText = nextQuestion?.Text ?? "";
			const nextQuestionType = nextQuestion?.Type ?? "";
			const nextQuestionAnswers = nextQuestion?.Answers;

			if (nextQuestionType === QuestionType.FreeTextInput) {
				this.MessageHistory.push({
					Id: this.MessageHistory.length + 1,
					Text: nextQuestionText,
					QuestionType: nextQuestionType,
					Type: MessageTypes.NORMAL,
					Sender: MessageSender.WILLI,
					AutoScroll: true,
				});
			} 
			else {
				this.MessageHistory.push(
					{
						Id: this.MessageHistory.length + 1,
						Text: nextQuestionText,
						QuestionType: nextQuestionType,
						Type: MessageTypes.NORMAL,
						Sender: MessageSender.WILLI,
						AutoScroll: true,
					},
					{
						Id: this.MessageHistory.length + 1,
						Text: "",
						Type: MessageTypes.ANSWERSELECTION,
						Sender: MessageSender.WILLI,
						QuestionType: nextQuestionType,
						AnswerSelection: nextQuestionAnswers,
						AnswerAction: this.SetAnswerForQuiz,
						AutoScroll: false,
					}
				);
			}
		} 
		else {
			const nextQuestion = lastAnswer?.NextQuestion
				? this.ExtraQuiz?.Questions.find((q) => q.Id === lastAnswer.NextQuestion)
				: this.ExtraQuiz?.Questions[0];

			if (nextQuestion) this.ExtraQuiz?.QuestionIdHistory.push(nextQuestion.Id);

			const nextQuestionText = nextQuestion?.Text ?? "";
			const nextQuestionType = nextQuestion?.Type ?? "";
			const nextQuestionAnswers = nextQuestion?.Answers;

			this.MessageHistory.push(
				{
					Id: this.MessageHistory.length + 1,
					Text: nextQuestionText,
					QuestionType: nextQuestionType,
					Type: MessageTypes.NORMAL,
					Sender: MessageSender.WILLI,
					AutoScroll: true,
				},
				{
					Id: this.MessageHistory.length + 1,
					Text: "",
					Type: MessageTypes.ANSWERSELECTION,
					Sender: MessageSender.WILLI,
					QuestionType: nextQuestionType,
					AnswerSelection: nextQuestionAnswers,
					AnswerAction: this.SetAnswerForQuiz,
					AutoScroll: false,
				}
			);
		}
	}

	async SetAnswerForQuiz(
		company: Company,
		answer: Answer,
		quizId?: number,
		isExpertQuizCome?: boolean
	) {
		this.MessageHistory.pop();
		if (isExpertQuizCome) {
			this.isExpertQuizSet = true;
		}

		if (this.ExpertQuiz) {
			const questionId =
				this.ExpertQuiz.QuestionIdHistory[this.ExpertQuiz.QuestionIdHistory.length - 1];
			QuizService.sendAnswer(
				questionId,
				answer.Text ?? answer.Value ?? answer.Score ?? answer.Id,
				answer,
				this.ExpertQuiz.Questions.find((q) => q.Id === questionId),
				quizId
			);
		} else if (this.ExtraQuiz) {
			const questionId =
				this.ExtraQuiz.QuestionIdHistory[this.ExtraQuiz.QuestionIdHistory.length - 1];
			QuizService.sendAnswer(
				questionId,
				answer.Text ?? answer.Value ?? answer.Score ?? answer.Id,
				answer,
				this.ExtraQuiz.Questions.find((q) => q.Id === questionId),
				quizId
			);
		}

		this.MessageHistory.push({
			Id: this.MessageHistory.length + 1,
			Text: answer.Text ?? "",
			Type: MessageTypes.NORMAL,
			Sender: MessageSender.USER,
		});

		this.Loading = true;

		if (
			this.ExtraQuiz?.Questions.find((q) => q.Id === answer.NextQuestion)?.Type ===
			QuestionType.ProfileChoice
		) {
			// If next Question is Profile Choice, get answer from API
			const res = await this.SendMessage(
				company,
				undefined,
				company.DisplayedTexts[
					"Gib mir zu diesen Infos Produktempfehlungen mit Hinweis auf 3 passende Produkte als kurzen Text mit maximal 400 Zeichen."
				],
				true,
				this.ExtraQuiz.Id
			);
		} else if (
			this.ExpertQuiz?.Questions.find((q) => q.Id === answer.NextQuestion)?.Type ===
			QuestionType.ProfileChoice
		) {
			// If next Question is Profile Choice, get answer from API
			const res = await this.SendMessage(
				company,
				undefined,
				company.DisplayedTexts[
					"Gib mir zu diesen Infos Produktempfehlungen mit Hinweis auf 3 passende Produkte als kurzen Text mit maximal 400 Zeichen."
				],
				true,
				this.ExpertQuiz.Id
			);
		} else {
			setTimeout(async () => {
				this.Loading = false;
				this.PushNextQuizQuestion(answer);
				await nextTick();
				await nextTick();
				const messageWindow = GetDocumentElementById("messages");
				if (messageWindow) messageWindow.scrollTop = messageWindow.scrollHeight;
			}, 1000);
		}
	}

	async SendProductRecommendationInChat(language: Languages) {
		const filteredAnswers = this.MessageHistory.filter(
			(message) =>
				message.Sender === MessageSender.WILLI && message.Type === MessageTypes.NORMAL
		);

		const idOfLastAnswer = filteredAnswers[filteredAnswers.length - 1].Id;

		const answer = this.MessageHistory.find((message) => message.Id === idOfLastAnswer);

		const recommendations = await this.GetProductRecommendationForAnswer(answer?.Text ?? "", language);

		if (recommendations && recommendations.length > 0) {
			let productsToUse = recommendations.filter(
				(product) => product.PictureFront !== null && product.Price && product.Price > 6
			);

			if (!productsToUse || productsToUse.length < 5)
				productsToUse = recommendations.filter((product) => product.Price && product.Price > 6);

			if (!productsToUse || productsToUse.length < 5) productsToUse = recommendations;

			this.MessageHistory.push({
				Id: this.MessageHistory.length + 1,
				Text: "",
				Type: MessageTypes.WINECARD,
				Sender: MessageSender.WILLI,
				ProductRecommendations: productsToUse ?? recommendations,
			});
		}

		return;
	}

	clearHistory() {
		this.MessageHistory = this.MessageHistory.map((message) => {
			// make each message as Ignore type
			// When message asked for an action, that was not provided, delete the message

			if (message.Type === MessageTypes.ONEBUTTON || message.Type === MessageTypes.TWOBUTTONS)
				return undefined;

			if (message.Type === MessageTypes.WINECARD) return message;

			return {
				...message,
				Type: MessageTypes.IGNOREINHISTORY,
			} as Message;
		}).filter((e) => e !== undefined) as Message[];
	}

	async getTasteOffers(company: Company) {
		this.clearHistory();

		this.MessageHistory.push({
			Id: this.MessageHistory.length + 1,
			Text: company.DisplayedTexts[
				"Hier sind die aktuellen Angebote, passend zu deinem Geschmack:"
			],
			Type: MessageTypes.NORMAL,
			Sender: MessageSender.WILLI,
		} as Message);

		this.Loading = true;
		const tasteMatchedOffers = await QuizService.getSavedSessionMatch(
			localStorage.getItem("sessionId")!,
			localStorage.getItem("sessionHash")!,
			true
		);

		this.MessageHistory.push({
			Id: this.MessageHistory.length + 1,
			Text: "",
			Type: MessageTypes.OFFERCARD,
			Sender: MessageSender.WILLI,
			ProductRecommendations: tasteMatchedOffers.match.Products.slice(0, 5),
		});

		this.Loading = false;
	}

	get Loading() {
		return this._loading;
	}

	set Loading(val: boolean) {
		this._loading = val;
	}
}
