import { App, createApp } from "vue";

import { defineCustomElement } from "./defineCustomElementWithStyles";
import ProductFinder from "./App.vue";

// import VueMatomo from "vue-matomo";
import router from "./router";
import clickOutsideHandler from "./plugins/clickOutsideHandler";

// When in Dev mode, run as normal App
// When Production, build as webComponent

if (process.env.NODE_ENV == "development") {
	const app: App<Element> = createApp(ProductFinder);
	app.config.unwrapInjectedRef = true;

	app.use(router);

	app.use(clickOutsideHandler);

	app.mount("#ap-productfinder");
} else {
	customElements.define("ap-productfinder", defineCustomElement(ProductFinder));
}
