<template>
	<img id="imagePositionClick" v-bind="$props" />
</template>

<script>
import GetDocumentElementById from "@/utils/GetDocumentElementById";

export default {
	data() {
		return {
			myImg: null,
			posX: 0,
			posY: 0,
		};
	},
	props: ["src", "class"],
	emits: ["onImageClick"],
	mounted() {
		this.myImg = GetDocumentElementById("imagePositionClick");

		if (this.myImg) this.myImg.onmousedown = this.getCoordinates;
	},
	methods: {
		findPosition(oElement) {
			if (typeof oElement.offsetParent != "undefined") {
				for (var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent) {
					posX += oElement.offsetLeft;
					posY += oElement.offsetTop;
				}
				return [posX, posY];
			} else {
				return [oElement.x, oElement.y];
			}
		},
		getCoordinates(e) {
			var PosX = 0;
			var PosY = 0;
			var ImgPos;
			ImgPos = this.findPosition(this.myImg);
			if (e.pageX || e.pageY) {
				PosX = e.pageX;
				PosY = e.pageY;
			} else if (e.clientX || e.clientY) {
				PosX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
				PosY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
			}
			PosX = PosX - ImgPos[0];
			PosY = PosY - ImgPos[1];
			this.posX = PosX;
			this.posY = PosY;
			this.$emit("onImageClick", {
				width: this.myImg?.offsetWidth,
				posX: this.posX,
				posY: this.posY,
			});
		},
	},
};
</script>

<style scoped>
img{
	width: auto;
	height: auto;
}
</style>
