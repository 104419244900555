
import { defineComponent, PropType } from "vue";
import MultiplePictureChoiceButton from "@/components/Elements/MultiplePictureChoiceButton.vue";
import Company from "@/entities/domain/Company/Company";
import { Answer } from "@/entities/domain/Quiz/Answer";
import { Question } from "@/entities/domain/Quiz/Question";

export default defineComponent({
	name: "SinglePictureChoice",
	components: { MultiplePictureChoiceButton },
	props: {
		company: {
			type: Object as PropType<Company>,
			required: true
		},
		question: {
			type: Object as PropType<Question>,
			required: false
		}
	},
	emits: ["answerClicked", "setAnswer"],
	data() {
		return { selectedAnswerId: -1 };
	},
	computed: {
		answers(): Answer[] {
			if(this.question)
				return this.question.Answers;

			return this.company?.Quiz.getCurrentQuestion().Answers ?? [];
		},
		frontendSettings() {
			if(this.question)
				return this.question.FrontendSettings;

			return this.company?.Quiz.getCurrentQuestion().FrontendSettings;
		}
	},
	mounted() {
		this.$emit("answerClicked", null);
	},
	methods: {
		answerClicked(answer: Answer) {
			this.selectedAnswerId = answer.Id;
			this.$emit("setAnswer", { answerId: answer.Id, answerValue: Number(answer.Score) });
		},
	},
});
