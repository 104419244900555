
import { defineComponent, PropType } from "vue";
import Company from "@/entities/domain/Company/Company";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "MultipleChoice",
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
	emits: ["answerClicked"],
	data() {
		return { selectedAnswerIds: [] as number[], showInfoText: false };
	},
	computed: {
		answers(): Answer[] {
			return this.company?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
	methods: {
		answerClicked(answer: Answer) {
			const foundIndex = this.selectedAnswerIds.indexOf(answer.Id);
			if (foundIndex === -1) this.selectedAnswerIds.push(answer.Id);
			// Add Answer when not already present in Array
			else this.selectedAnswerIds.splice(foundIndex, 1); // Remove Answer from Array

			const currentScores = this.answers
				.filter((answer) => this.selectedAnswerIds.indexOf(answer.Id) !== -1)
				.map((answer) => Number(answer.Score));

			this.$emit("answerClicked", {
				answerId: answer.Id,
				answerValue: currentScores,
			});
		},
	},
});
