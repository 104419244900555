
import { defineComponent, PropType } from "vue";
import SingleChoice from "@/components/Question/SingleChoice.vue";
import MultipleChoice from "@/components/Question/MultipleChoice.vue";
import MultiplePictureChoice from "@/components/Question/MultiplePictureChoice.vue";
import BinaryChoice from "@/components/Question/BinaryChoice.vue";
import StartChoice from "@/components/Question/StartChoice.vue";
import SwipeChoice from "@/components/Question/SwipeChoice.vue";
import ProfileChoice from "@/components/Elements/Result/ProfileChoice.vue";
import SteplessSliderChoice from "@/components/Question/SteplessSliderChoice.vue";
import IntermediateScreenWaves from "./Question/IntermediateScreenWaves.vue";
import SingleChipSelection from "@/components/Question/SingleChipSelection.vue";
import MultipleChipSelection from "@/components/Question/MultipleChipSelection.vue";
import VersusChoice from "@/components/Question/VersusChoice.vue";
import EmailChoice from "@/components/Question/EmailChoice.vue";
import SinglePictureChoice from "@/components/Question/SinglePictureChoice.vue";
import NumberInput from "@/components/Question/NumberInput.vue";
import QuizRestart from "@/components/Question/QuizRestart.vue";
import Company from "@/entities/domain/Company/Company";

export default defineComponent({
	name: "Question",
	components: {
		SingleChoice,
		MultipleChoice,
		MultiplePictureChoice,
		BinaryChoice,
		StartChoice,
		SwipeChoice,
		ProfileChoice,
		SteplessSliderChoice,
		IntermediateScreenWaves,
		SingleChipSelection,
		MultipleChipSelection,
		VersusChoice,
		EmailChoice,
		SinglePictureChoice,
		NumberInput,
		QuizRestart,
	},
	data() {
		return {
			questionLoadedAtStamp: Date.now(),
		};
	},
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
	emits: ["answerClicked", "setAnswer", "restartQuiz", "closeQuiz"],
	methods: {
		async setAnswer(event) {
			// if question is speed depended check for faster than 3 seconds
			if (
				this.company?.Quiz.getCurrentQuestion().QuestionSpeedMode &&
				Date.now() - this.questionLoadedAtStamp < 3000
			) {
				await this.company?.setAnswerAsync(event, true);
			}
			else{
				await this.company?.setAnswerAsync(event);
			}

		},
		onAnswerClicked(event) {
			this.company?.onAnswerClicked(event);
		},
	},
	//watch company Quiz QuestionIdHistory for changes
	watch: {
		"company.Quiz.QuestionIdHistory": {
			handler() {
				this.questionLoadedAtStamp = Date.now();
			},
			deep: true,
		},
	},
});
