import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77215c36"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "picture" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Blob = _resolveComponent("Blob")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["MultiplePictureChoiceButton", { selected: _ctx.selectedAnswer, small: _ctx.small && !_ctx.versusMode }]),
    title: _ctx.answer.Text ?? ''
  }, [
    _createElementVNode("label", {
      class: "button",
      for: _ctx.answer.Id.toString()
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "foreground",
          src: _ctx.getImg(_ctx.answerImage),
          alt: _ctx.answer.Text ?? ''
        }, null, 8, _hoisted_4),
        _createVNode(_component_Blob, { class: "activeBlob" })
      ])
    ], 8, _hoisted_2),
    (_ctx.showCaption)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "caption",
          for: _ctx.answer.Id.toString(),
          style: _normalizeStyle('color: ' + _ctx.textColor)
        }, _toDisplayString(_ctx.answer.Text), 13, _hoisted_5))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}