
import Company from "@/entities/domain/Company/Company";
import { Translations } from "@/entities/domain/Translations";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "Navigation",
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
	emits: ["prevQuestion", "nextQuestion"],
	computed: {
		currentAnswerValue() {
			return this.company?.Quiz.CurrentSelectedAnswer?.Value ?? null;
		},
		baseComponentTranslations(): Translations {
			return this.company?.baseComponentTranslations ?? ({} as Translations);
		},
		prevButtonIsVisible(): boolean {
			if (!this.company?.Quiz.QuestionIdHistory) return false;

			return this.company?.Quiz.QuestionIdHistory.length > 1;
		},
		answerIsSelected() {
			if (this.currentAnswerValue === undefined || this.currentAnswerValue === null) {
				return false;
			}

			if (typeof this.currentAnswerValue === "object") {
				return Object.keys(this.currentAnswerValue).length > 0;
			}

			if (Array.isArray(this.currentAnswerValue)) return this.currentAnswerValue.length > 0;

			return true;
		},
	},
});
