
import { defineComponent, PropType } from 'vue'

import StartChoice from '@/components/Question/StartChoice.vue'
import Company from '@/entities/domain/Company/Company'

export default defineComponent({
  name: 'QuizSelectionPage',
  components: {
    StartChoice
  },
  props: {
    company: {
      type: Object as PropType<Company>
    }
  }
})
