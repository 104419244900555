import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6982850b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.company?.homepageMode)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.brandLogoImageSource,
          class: "logo",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logoClicked && _ctx.logoClicked(...args)))
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}