import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a4c5726"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.company)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["SingleChipSelection", { withQuestionImage: _ctx.company?.Quiz.getCurrentQuestion().Picture }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.ceil(_ctx.company.Quiz.getCurrentQuestion().Answers.length / 5), (index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex auto"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.company.Quiz.getCurrentQuestion().Answers.slice(
					(index - 1) * 5,
					index * 5
				), (answer) => {
              return (_openBlock(), _createElementBlock("div", {
                id: answer.Id.toString(),
                key: answer.Id,
                class: _normalizeClass(["chip", { selected: _ctx.selectedChipAnswerId === answer.Id }]),
                onClick: ($event: any) => (_ctx.answerClicked(answer)),
                style: _normalizeStyle({color: _ctx.company.CompanyQuizConfig.DarkMode ? _ctx.company.FrontendDesign?.PrimaryFontColor : undefined})
              }, _toDisplayString(answer.Text), 15, _hoisted_1))
            }), 128))
          ]))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}