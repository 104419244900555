
import Loading from "@/components/BaseComponents/Loading.vue";
import ProfileChoice from "@/components/Elements/Result/ProfileChoice.vue";
import { Match } from "@/entities/domain/Match/Match";
import Company from "@/entities/domain/Company/Company";
import { Translations } from "@/entities/domain/Translations";
import AutoScrollToId from "@/utils/AutoScrollToId";
import GetDocumentElementById from "@/utils/GetDocumentElementById";
import { defineComponent, nextTick, PropType } from "vue";

export default defineComponent({
	name: "QuizResultPage",
	components: { ProfileChoice, Loading },
	props: {
		company: {
			type: Object as PropType<Company>,
			required: true,
		},
	},
	data() {
		return {
			loadingDelay: 2000,
			errorWhileLoading: false,
			scrolledToBottom: false,
		};
	},
	watch: {
		company: {
			handler(newVal: Company) {
				if (newVal.Match && this.loadingDelay === 0 && !this.scrolledToBottom)
					this.startAutoScroll();
			},
			deep: true,
		},
	},
	computed: {
		baseComponentTranslations(): Translations | undefined {
			return this.company?.baseComponentTranslations;
		},
		hostUrl() {
			return window.location.host;
		},
		match(): Match {
			return this.company.Match ?? ({} as Match);
		},
	},
	methods: {
		async setAnswer(event) {
			await this.company.setAnswerAsync(event);
		},
		onAnswerClicked(event) {
			this.company.onAnswerClicked(event);
		},
		scrollToAsync(val) {
			return new Promise(function (resolve) {
				setTimeout(function () {
					resolve(GetDocumentElementById("rootContainer")?.scrollTo(0, val));
				}, 0.1);
			});
		},
		async startAutoScroll() {
			await nextTick(); // Otherwhise the autoscrolling happens to early

			this.scrolledToBottom = true;

			AutoScrollToId("profileFooter", "auto", "end");

			// setTimeout(() => AutoScrollToId("header", "smooth", "start"), 500);

			const scrollHeight = GetDocumentElementById("rootContainer")!.scrollHeight;

			let scrollTo = scrollHeight;

			const stepSize = 8;

			let i = 0;

			while ((i < scrollHeight / stepSize) && window.location.hash.includes("result")) {
				await this.scrollToAsync(scrollTo);
				scrollTo -= stepSize;
				i++;
			}
		},
	},
	mounted() {
		setTimeout(() => {
			this.loadingDelay = 0;

			if (!this.scrolledToBottom && this.company.CompanyQuizConfig.ChatbotMode?.QuizOnlyMode) this.startAutoScroll();
		}, this.loadingDelay);

		setTimeout(() => {
			if (!this.company.Match || this.loadingDelay) this.errorWhileLoading = true;
		}, 5000);
	},
});
