import { IProductResponse } from "@/interfaces/api/responses/Matching/IProductResponse";

export class Product {
	Id: string;
	Name: string;
	Description: string;
	GTIN?: string;
	Region: string;
	ContentInMl?: string;
	Country?: string;
	Category?: string;
	TasteMatch: number;
	Handle: string;
	PictureFront: string;
	Price?: number;
	OfferPrice?: number;
	Aroma?: string;

	CardFrontData: Record<string, any>;
	CardBackData: Record<string, any>;

	constructor(productType: Product) {
		this.Id = productType.Id;
		this.Name = productType.Name;
		this.Description = productType.Description;
		this.GTIN = productType.GTIN;
		this.Region = productType.Region;
		this.ContentInMl = productType.ContentInMl;
		this.Country = productType.Country;
		this.Category = productType.Category;
		this.TasteMatch = productType.TasteMatch;
		this.Handle = productType.Handle;
		this.PictureFront = productType.PictureFront;
		this.Price = productType.Price;
		this.OfferPrice = productType.OfferPrice;
		this.Aroma = productType.Aroma;

		this.CardFrontData = productType.CardFrontData;
		this.CardBackData = productType.CardBackData;
	}

	static createFromApiResponse(response: IProductResponse): Product {
		const newProductType = {
			Id: response.id,
			Name: response.name,
			Description: response.description,
			GTIN: response.GTIN,
			Region: response.region,
			Country: response.country,
			Category: response.category,
			TasteMatch: response.matchingScore,
			Handle: response.link,
			PictureFront: response.image_url,
			Price: response.price,
			OfferPrice: response.offer_price,
			Aroma: response.aroma,

			CardFrontData: response.cardFrontData,
			CardBackData: response.cardBackData
		} as Product;

		return newProductType;
	}
}
