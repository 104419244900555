import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a547e81a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "SinglePictureChoice" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiplePictureChoiceButton = _resolveComponent("MultiplePictureChoiceButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer) => {
        return (_openBlock(), _createBlock(_component_MultiplePictureChoiceButton, {
          key: answer.Id,
          answer: answer,
          showCaption: 
					_ctx.answers.length > 3 ||
						_ctx.frontendSettings &&
						(_ctx.frontendSettings.find(
							(el) => el.Key === 'ShowAnswerText'
						)?.Value === 'true')
				,
          small: _ctx.answers.length > 3,
          selectedAnswer: _ctx.selectedAnswerId === answer.Id,
          class: _normalizeClass(["choiceButton", _ctx.answers.length % 2 == 0 ? 'even' : 'odd']),
          onClick: ($event: any) => (_ctx.answerClicked(answer)),
          textColor: _ctx.company?.CompanyQuizConfig.DarkMode ? '#ffffff' : '#757575',
          selectedAnswerTags: _ctx.company?.Quiz.getAllSelectedAnwerTags()
        }, null, 8, ["answer", "showCaption", "small", "selectedAnswer", "class", "onClick", "textColor", "selectedAnswerTags"]))
      }), 128))
    ])
  ]))
}