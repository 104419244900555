export default [
	"Möchtest du dein Lieblingsprodukt finden? Ich helfe dir!",
	"Passend zum Essen",
	"Passend als Geschenk",
	"Passend zu meinem Geschmack",
	"Jetzt Lieblingsprodukt finden",
	"Wofür suchst du ein Produkt?",
	"Willkommen beim WineWizard.",
	"Ich habe folgenden Geschmackstypen:",
	"Folgende Aromen schmecken mir:",
	"Berücksichtige diese Infos bei deinen Empfehlungen.",
	"Die Anfrage hat zu lange gedauert, versuche es bitte später noch einmal.",
	"Leider kann ich dir gerade keine Antwort geben, versuche es bitte später noch einmal.",
	"Erneut senden",
	"War diese Antwort hilfreich?",
	"Hier Speise eingeben",
	"Ja",
	"Nein",
	"Verkostung starten",
	"Jetzt kaufen",
	"Nicht verfügbar",
	"zur Karte",
	"Navigation öffnen",
	"Speichern",
	"Jetzt Entdecken",
	"Preis",
	"Geschmack",
	"Marke",
	"Rebsorte",
	"Süßegrad",
	"Säuregehalt",
	"Etikett",
	"Zu modern",
	"Zu altbacken",
	"Zu billig",
	"Zu kompliziert",
	"Oh, das ist sehr schade.",
	"Woran lag es?",
	"Quiz Neustarten",
	"Beim laden des Quizzes ist ein Fehler aufgetreten.",
	"Bitte versuche es später noch einmal.",
	"Bewege den Slider, um eine Auswahl zu treffen.",
	"Für welchen",
	"Anlass",
	"suchen Sie ein Produkt",
	"suchst du ein Produkt",
	"Bitte auswählen",
	"Das Quiz wird beendet ...",
	"Achtung, Mehrfachauswahl möglich",
	"Mehrfachauswahl möglich",
	"E-Mail-Adresse",
	"Beim Absenden akzeptierst du unsere",
	"Datenschutzbestimmungen",
	"Name ist erforderlich.",
	"E-Mail-Adresse ist erforderlich.",
	"Falsches E-Mail Format.",
	"Sie müssen den Datenschutzbestimmungen zustimmen.",
	"Bitte wende dich an das Personal, um zu dein Produkt zu gelangen.",
	"Zurück",
	"Weiter",
	"Neustart",
	"Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuche es erneut.",
	"By starting the quiz you agree with our",
	"Mit dem Start des Quiz stimmst du unseren",
	"and our",
	"und der",
	"zu.",
	"AGBs",
	"AGB",
	"Ihr Geschmacksprofil ist",
	"Dein Geschmacksprofil ist",
	"Passend zum Essen",
	"Lesen Sie mehr zu Ihrem Geschmacks-Profil",
	"Lies mehr zu deinem Geschmacks-Profil",
	"Was macht Ihren",
	"Was macht deinen <underline>Lieblingsprodukt</underline> aus?",
	"Neuen Lieblingsprodukt finden",
	"Dein Geschmacksprofil wird analysiert und ausgewertet ...",
	"Ihr Geschmacksprofil wird analysiert und ausgewertet ...",
	"Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuche es erneut.",
	"NEUES GESCHMACKSPROFIL",
	"Diese Produkte passen perfekt zu Ihnen:",
	"Diese Produkte passen perfekt zu dir:",
	"Gespeichert",
	"Produktauswahl",
	"Farbe",
	"Dauer",
	"Fragen",
	"Vielen Dank für dein Feedback! Möchtest du ein weiteres Produkt finden?",
	"Weiteres Produkt finden",
	"Produktquiz starten",
	"Was für ein Produkt suchst du?",
	"Hier Suche eingeben",
	"Was für ein Produkt suchen Sie?",
	"Alles klar",
	"Alles klar!",
	"Angebote genau nach deinem Geschmack",
	"Hier sind die aktuellen Angebote, passend zu deinem Geschmack:",
	"Welches Produkt suchen Sie?",
	"Welches Produkt suchst du?",
	"Hier Rebsorten eingeben",
	"mehr Anzeigen",
	"dein Geschmack",
	"Schmeckt zu",
	"Schließen",
	"Hallo & herzlich Willkommen!",
	"Wie kann ich dir helfen?",
	"Kundenservice kontaktieren",
	"Kundenservice",
	"Bevor wir starten",
	"Vielen Dank für dein Feedback! Mächstes du ein weiteres Produkt suchen?",
	"Wie hat dir das Quiz gefallen?",
	"Vielen Dank für dein Feedback! Möchtest du einen Rabatt für dein Lieblingsprodukt erhalten?",
	"Diese Produkte passen perfekt zu Ihnen:",
	"Diese Produkte passen perfekt zu dir:",
	"Deine Antworten werden analysiert und ausgewertet ...",
	"Ihre Antworten werden analysiert und ausgewertet ...",
	"Optik",
	"Was für ein Produkt suchen Sie?",
	"Supersearch",
	"Hallo, ich bin der ProductWizard, dein digitaler Produktberater",
	"Wie kann ich dir heute helfen?",
	"Welches Produkt suchst du?",
	"Super-Suche starten",
	"Produktsuche-Vorschlag",
	"quizPath1",
	"quizPath2",
	"quizPath3",
] as string[];
