import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03c317d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "tt_wrapper",
  id: "resultPage"
}
const _hoisted_2 = { class: "explanation" }
const _hoisted_3 = {
  key: 0,
  class: "loading"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "restartInfo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ProfileChoice = _resolveComponent("ProfileChoice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.company.Quiz.getCurrentQuestion().Explanation), 1),
    (!_ctx.company.Match || _ctx.loadingDelay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!_ctx.errorWhileLoading)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.company.CompanyQuizConfig.UseFormalSalutation ?
					_ctx.company.DisplayedTexts["Ihre Antworten werden analysiert und ausgewertet ..."]
					:
					_ctx.company.DisplayedTexts["Deine Antworten werden analysiert und ausgewertet ..."]), 1))
            : _createCommentVNode("", true),
          (!_ctx.errorWhileLoading)
            ? (_openBlock(), _createBlock(_component_Loading, { key: 1 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.company.DisplayedTexts["Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuche es erneut."]) + " ", 1),
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('restartQuiz')))
                  }, _toDisplayString(_ctx.company.DisplayedTexts["NEUE SUCHE"]), 1)
                ])
              ]))
        ]))
      : (_openBlock(), _createBlock(_component_ProfileChoice, {
          key: 1,
          class: "component",
          company: _ctx.company,
          onCloseQuiz: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeQuiz')))
        }, null, 8, ["company"]))
  ]))
}