
import { defineComponent, PropType } from "vue";
import MultiplePictureChoiceButton from "@/components/Elements/MultiplePictureChoiceButton.vue";
import Company from "@/entities/domain/Company/Company";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "VersusChoice",
	components: { MultiplePictureChoiceButton },
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
	emits: ["answerClicked", "setAnswer"],
	data() {
		return { selectedAnswerId: -1 };
	},
	computed: {
		answers(): Answer[] {
			return this.company?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
	methods: {
		answerClicked(answer: Answer) {
			this.selectedAnswerId = answer.Id;
			this.$emit("setAnswer", { answerId: answer.Id, answerValue: answer.Text }); // TODO: Check if this works vs text_de
		},
		getThumbImage() {
			if (this.company?.FrontendDesign?.VsThumbImage) {
				return this.company.FrontendDesign.VsThumbImage;
			}

			return require("@/assets/img/vs.svg");
		},
	},
});
