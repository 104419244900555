import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51e8279f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  ref: "container",
  class: "container"
}
const _hoisted_3 = { class: "waveBox" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  ref: "bottom",
  class: "background"
}
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          ref: "waveOne",
          src: 
						_ctx.getImg(
							_ctx.frontendDesign?.IntermediateScreenThreeImage ?? '/img/double_wave_3.svg'
						)
					,
          class: "waveOne"
        }, null, 8, _hoisted_4),
        _createElementVNode("img", {
          ref: "waveTwo",
          src: 
						_ctx.getImg(
							_ctx.frontendDesign?.IntermediateScreenTwoImage ?? '/img/double_wave_2.svg'
						)
					,
          class: "waveTwo"
        }, null, 8, _hoisted_5),
        _createElementVNode("img", {
          ref: "waveThree",
          src: 
						_ctx.getImg(
							_ctx.frontendDesign?.IntermediateScreenOneImage ?? '/img/double_wave_1.svg'
						)
					,
          class: "waveThree"
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", {
          class: "infoText",
          innerHTML: _ctx.answers[0].Text,
          style: _normalizeStyle({ color: _ctx.company.CompanyQuizConfig.DarkMode ? _ctx.company.FrontendDesign?.SecondaryColor : _ctx.company.FrontendDesign?.BackgroundColor })
        }, null, 12, _hoisted_8),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toNextQuestion && _ctx.toNextQuestion(...args))),
          style: {"background-color":"var(--background-color)","margin-top":"4vh"}
        }, _toDisplayString(_ctx.counterText > 0 ? _ctx.counterText : "Go!"), 1)
      ], 512)
    ], 512)
  ]))
}