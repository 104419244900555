
import { defineComponent, PropType } from "vue";

import Question from "@/components/Question.vue";
import Navigation from "@/components/Elements/Navigation.vue";
import Company from "@/entities/domain/Company/Company";
import ProgressBar from "@/components/Elements/ProgressBar.vue";

export default defineComponent({
	name: "QuestionPage",
	components: {
		Question,
		Navigation,
		ProgressBar,
	},
	props: {
		company: {
			type: Object as PropType<Company>,
			default: {} as Company,
		},
	},
	async mounted() {
		if (
			!this.company?.Quiz.QuestionIdHistory ||
			this.company?.Quiz.QuestionIdHistory.length <= 0
		) {
			await this.$router.push("/");
		}
	},
});
