import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-432b734a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tt_wrapper" }
const _hoisted_2 = { class: "question" }
const _hoisted_3 = { class: "explanation" }
const _hoisted_4 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SinglePictureChoice = _resolveComponent("SinglePictureChoice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.languageQuestion.Explanation), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.languageQuestion.Text), 1),
      _createVNode(_component_SinglePictureChoice, {
        company: _ctx.company,
        question: _ctx.languageQuestion,
        class: "component",
        onSetAnswer: _ctx.setAnswer
      }, null, 8, ["company", "question", "onSetAnswer"])
    ])
  ]))
}