import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a858eb8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "MultiplePictureChoice" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiplePictureChoiceButton = _resolveComponent("MultiplePictureChoiceButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex", { 'grid-layout': _ctx.answers.length > 5 }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer) => {
        return (_openBlock(), _createBlock(_component_MultiplePictureChoiceButton, {
          key: answer.Id,
          answer: answer,
          showCaption: _ctx.answers.length > 3 ||
				_ctx.company?.Quiz.getCurrentQuestion().FrontendSettings.find(
					(el) => el.Key === 'showAnswerText'
				)?.Value === 'true'
				,
          small: _ctx.answers.length > 3,
          selectedAnswer: _ctx.isAnswerSelected(answer),
          class: _normalizeClass(["choiceButton", {
					even: _ctx.answers.length % 2 == 0,
					odd: !(_ctx.answers.length % 2 == 0),
					selected: _ctx.isAnswerSelected(answer),
				}]),
          onClick: ($event: any) => (_ctx.answerClicked(answer)),
          textColor: _ctx.company?.CompanyQuizConfig.DarkMode ? '#ffffff' : '#757575',
          selectedAnswerTags: _ctx.company?.Quiz.getAllSelectedAnwerTags()
        }, null, 8, ["answer", "showCaption", "small", "selectedAnswer", "class", "onClick", "textColor", "selectedAnswerTags"]))
      }), 128))
    ], 2)
  ]))
}