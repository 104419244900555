
import { defineComponent, PropType } from "vue";
import Company from "@/entities/domain/Company/Company";

export default defineComponent({
	name: "SingleChoice",
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
	emits: ["setAnswer"],
	computed: {
		answers() {
			return this.company?.Quiz.getCurrentQuestion().Answers;
		},
	},
});
