
import { defineComponent, PropType } from "vue";
import MultiplePictureChoiceButton from "@/components/Elements/MultiplePictureChoiceButton.vue";
import Company from "@/entities/domain/Company/Company";
import { Answer } from "@/entities/domain/Quiz/Answer";
import { Question } from "@/entities/domain/Quiz/Question";

export default defineComponent({
	name: "SinglePictureChoice",
	components: { MultiplePictureChoiceButton },
	props: {
		company: {
			type: Object as PropType<Company>,
			required: true
		},
		question: {
			type: Object as PropType<Question>,
			required: false
		}
	},
	emits: ["answerClicked", "setAnswer"],
	data() {
		return { selectedAnswerId: -1 };
	},
	computed: {
		answers(): Answer[] {
			if (this.question)
				return this.question.Answers;

			return this.company?.Quiz.getCurrentQuestion().Answers ?? [];
		},
		frontendSettings() {
			if (this.question)
				return this.question.FrontendSettings;

			return this.company?.Quiz.getCurrentQuestion().FrontendSettings;
		}
	},
	mounted() {
		this.$emit("answerClicked", null);

		// When current question is the first question, check if answeredText is set in localStorage and use that to select first answer
		if (this.company?.Quiz.QuestionIdHistory.length === 1) {
			const answeredText = localStorage.getItem("answeredText");
			localStorage.removeItem("answeredText");
			if (answeredText) {
				const answer = this.answers.find((a) => a.Text && answeredText.toLowerCase().includes(a.Text?.toLowerCase()));
				if (answer) {
					this.answerClicked(answer);
				}
			}
		}
	},
	methods: {
		answerClicked(answer: Answer) {
			this.selectedAnswerId = answer.Id;
			this.$emit("setAnswer", { answerId: answer.Id, answerValue: Number(answer.Score) });
		},
	},
});
