import { IMatchingResponse } from "@/interfaces/api/responses/Matching/IMatchingResponse";
import { CompanyQuizConfig } from "../Company/CompanyQuizConfig";
import { AromaType } from "./AromaType";
import { GrapeType } from "./GrapeType";
import { TasteType } from "./TasteType";
import { Product } from "./Product";

export class Match {
	// TasteType: TasteType;
	// Grapes: GrapeType;
	// Aromas: AromaType[];
	Products: Product[];
	// QrUrl: string;
	SessionId: string;
	SessionHash: string;
	// ProvidedContact: boolean;
	// ClaimedProduct: boolean;
	// QrCodeMode: boolean;
	CompanyConfig?: CompanyQuizConfig;

	constructor(match: Match) {
		this.Products = match.Products;
		this.SessionId = match.SessionId;
		this.SessionHash = match.SessionHash;
		this.CompanyConfig = match.CompanyConfig;
	}

	static createFromApiResponse(response: IMatchingResponse): Match {
		const newMatch = {
			// TasteType: TasteType.createFromApiResponse(response.type),
			Products: response.products.map((product) => Product.createFromApiResponse(product)),
			SessionId: response.session_id,
			SessionHash: response.hash,
			CompanyConfig: response.config ? CompanyQuizConfig.createFromApiResponse(response.config) : null,
		} as Match;

		return newMatch;
	}
}
