export class TaggedImage {
	Picture: string;
	Tag: string;

	constructor(taggedImage: TaggedImage) {
		this.Picture = taggedImage.Picture;
		this.Tag = taggedImage.Tag;
	}

	static createFromApiResponse(response: any): TaggedImage {
		const newTaggedImage = new TaggedImage({
			Picture: response.asset,
			Tag: response.tag,
		});

		return newTaggedImage;
	}
}
