import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce0809f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "footer"
}
const _hoisted_2 = {
  key: 0,
  class: "legalNotice"
}
const _hoisted_3 = {
  key: 2,
  class: "refLink"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AGB = _resolveComponent("AGB")!
  const _component_DataPrivacy = _resolveComponent("DataPrivacy")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!

  return (_ctx.currentQuestion.Type !== 'ProfileChoice')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (
				_ctx.company?.Quiz.QuestionIdHistory && _ctx.company?.Quiz.QuestionIdHistory.length <= 2
				&& _ctx.currentQuestion.Type !== 'StartChoice'
			)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.company?.selectedLanguage !== 'en')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.company.DisplayedTexts["Mit dem Start des Quiz stimmst du unseren"]) + " ", 1),
                    _createElementVNode("span", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDialogFor = 'AGB'))
                    }, " " + _toDisplayString(_ctx.company.DisplayedTexts["AGBs"]) + " ", 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.company.DisplayedTexts["und der"]) + " ", 1),
                    _createElementVNode("span", {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDialogFor = 'data'))
                    }, " " + _toDisplayString(_ctx.company.DisplayedTexts["Datenschutzbestimmungen"]) + " ", 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.company.DisplayedTexts["zu."]), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.company.DisplayedTexts["By starting the quiz you agree with our"]) + " ", 1),
                    _createElementVNode("span", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDialogFor = 'AGB'))
                    }, " " + _toDisplayString(_ctx.company.DisplayedTexts["AGBs"]) + " ", 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.company.DisplayedTexts["and our"]) + " ", 1),
                    _createElementVNode("span", {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openDialogFor = 'data'))
                    }, " " + _toDisplayString(_ctx.company.DisplayedTexts["Datenschutzbestimmungen"]) + " ", 1),
                    _createTextVNode(". ")
                  ], 64))
            ]))
          : _createCommentVNode("", true),
        (_ctx.openDialogFor)
          ? (_openBlock(), _createBlock(_component_DialogComponent, {
              key: 1,
              onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openDialogFor = null)),
              style: {"text-align":"left"}
            }, {
              default: _withCtx(() => [
                (_ctx.openDialogFor === 'AGB')
                  ? (_openBlock(), _createBlock(_component_AGB, {
                      key: 0,
                      company: _ctx.company
                    }, null, 8, ["company"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_DataPrivacy, { company: _ctx.company }, null, 8, ["company"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.currentQuestion.Type !== 'StartChoice')
          ? (_openBlock(), _createElementBlock("a", _hoisted_3, "powered by Agentur Philipp"))
          : (
				(!_ctx.company?.CompanyQuizConfig.Languages || _ctx.company.languageWasSelected)
				&& !_ctx.company?.homepageMode
			)
            ? (_openBlock(), _createElementBlock("img", {
                key: 3,
                src: 
				_ctx.getImageFromSource(
					_ctx.company?.FrontendDesign?.SmartphoneImage ??
						'@/assets/img/basic_handy_quiz.svg'
				)
			,
                class: "startPhone",
                alt: "Handy"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}