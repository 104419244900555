
import Company from "@/entities/domain/Company/Company";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "CompanySelectionPage",
	props: {
		company: {
			type: Object as PropType<Company>,
			default: {} as Company,
		},
		snippetCompanyCode: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			standardCompanyCode: window.document.getElementById("ap-productfinder")?.getAttribute("company-code")
		};
	},
	async mounted() {
		// If CompanyCode is provided in url, use that
		const urlHashData = window.location.hash.slice(2); // Get everything after #/ in url
		if (
			urlHashData &&
			!urlHashData.includes("/") &&
			!this.$router.options.routes.find((route) =>
				urlHashData.includes(route.path.substring(1, route.path.indexOf("/:") - 1))
			)
		) {
			const oldCompanyCode = localStorage.getItem("companyCode");
			await this.company.loadCompanyAsync(urlHashData.toUpperCase(), true);
			const loadingResult = await this.company.loadCompanyAsync(urlHashData.toUpperCase(), true);
			if (!loadingResult.Success) {
				await this.company.loadCompanyAsync(oldCompanyCode ?? this.snippetCompanyCode, true)
			}

		}
		this.standardCompanyCode = window.document.getElementById("ap-productfinder")?.getAttribute("company-code")
	},
});
