
import { defineComponent, PropType } from "vue";
import Company from "@/entities/domain/Company/Company";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "SwipeChoice",
	props: {
		company: {
			type: Object as PropType<Company>,
		},
	},
	emits: ["answerClicked"],
	computed: {
		answers(): Answer[] {
			return this.company?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
});
